import dayjs from 'dayjs';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BackstageDashboardService } from 'src/app/Services/backstage-dashboard.service';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';

@Component({
  selector: 'app-utils',
  templateUrl: './utils.component.html',
  styleUrls: ['./utils.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UtilsComponent implements OnInit {

  constructor(
    private service: BackstageDashboardService,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
  }
}
