import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { AvailableOnService } from 'src/app/Services/available-on.service';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MovieDetails } from '../../models/MovieDetails';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';

@Component({
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.css']
})
export class EditDetailsComponent implements OnInit {

  @Input() movieDetailsData: MovieDetails;
  genresList: String[] = [];
  isLoading: boolean = false;
  youtubeKey: SafeResourceUrl;
  genreDisplayString: string = '';

  constructor(private service: AvailableOnService,
    private sanitizer: DomSanitizer,
    private snackBar: ServiceSnackbarService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.youtubeKey = null;
    this.genreDisplayString = '';
    this.populateGenres();
  }

  findUrl(): SafeResourceUrl {
    if (this.movieDetailsData.trailerUrl) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.movieDetailsData.trailerUrl.split('?v=')[1]);
    }
  }

  roleString(): boolean {
    if (this.movieDetailsData.genres) {
      this.genreDisplayString = '';
      this.movieDetailsData.genres.forEach(x => {
        this.genreDisplayString += x + ', ';
      });
    }
    return true;
  }

  populateGenres() {
    this.isLoading = true;
    this.service.fetchGenresList().subscribe(
      (res) => {
        res.map((x) => this.genresList.push(x.id))
        this.isLoading = false;
      }),
      (err) => {
        this.snackBar.open("Error while fetching genres");
      }
  }

  checkSelectedGenres(genre) {
    return this.movieDetailsData.genres.includes(genre);
  }

  updateGenre(genre: string) {
    if(this.checkSelectedGenres(genre)){
      this.movieDetailsData.genres = this.movieDetailsData.genres.filter((genreToRemove)=>{
        return genre != genreToRemove;
      })
    } else{
      this.movieDetailsData.genres.push(genre);
    }
    this.roleString();
  }

 onReleaseDateChange(event: MatDatepickerInputEvent<Date>) {
    this.movieDetailsData.releaseDate = this.datePipe.transform(event.target.value, "yyyy-MM-dd");
  }
 

  onSubmit() {
    this.service.updateContent(this.movieDetailsData);
  }
}
