import { NominatedFor } from '../../models/NominatedFor';
import { ServiceSnackbarService } from '../../Services/service-snackbar.service';
import { AvailableOnService } from '../../Services/available-on.service';
import { Nomination } from '../../models/Nomination';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AwardsCategoriesService } from 'src/app/Services/awards-categories.service';
import { SharedService } from 'src/app/Services/shared.service';
import { AwardCategory } from '../../models/AwardCategory';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-award-categories-content',
  templateUrl: './award-categories-content.component.html',
  styleUrls: ['./award-categories-content.component.css']
})
export class AwardCategoriesContentComponent implements OnInit {
  @ViewChild("ngAutocompleteApi", { static: true }) ngAutocompleteApi;

  awardNames: any[] = [];
  selectedAward;
  awardCategories: AwardCategory[] = [];
  selectedCategory: Nomination[];
  showNominations: boolean = false;
  createNominations: boolean = false;
  searchMovieSeries: boolean = false;
  searchContentData = [];
  searchCastData = [];
  searchNominationContentData = [];
  isLoading: boolean;
  searchNominationContentForm: FormGroup;
  searchNominationCastForm: FormGroup;
  selectedContentIndex: number;
  selectedCastIndex: number;
  selectedNominatedForIndex;

  public placeholder: string = "Find movies and shows";
  public castPlaceholder: string = "Find cast and crew";
  public nominationPlaceholder: string = "Find movies and shows for the cast/crew member";

  public keyword = "title";
  castkeyword = "name";
  public historyHeading: string = "Recently selected";
  selectedContentItems: Nomination[] = [];
  selectedCastItems: Nomination[] = [];

  selectedContentTemplate: any = '';
  regexStr = /'/g;
  nominationType: string;
  private currentAward: any;
  public currentAwardCategory: AwardCategory;
  counter = 0;
  categoryImageToUpload: any;
  categoryImageUrl: any = null;
  popup = false
  nominationImageUrl = ""
  selectedNominatedForItem: NominatedFor = null;
  currentSelectedCast: any;
  showCurrentSelectedCastPopUp: boolean = false;
  currentSelectedContent: any;
  showCurrentSelectedContentPopUp: boolean = false;
  contentImageToUpload: File;
  contentImageUrl: any;
  castImageToUpload: File;
  castImageUrl: any;
  downloadUrl: any;


  constructor(public sharedService: SharedService,
    private searchService: AvailableOnService,
    private fb: FormBuilder,
    private router: Router,
    private awardCategoriesService: AwardsCategoriesService,
    private snackBar: ServiceSnackbarService,
    private sanitizer: DomSanitizer) {
    this.searchNominationContentForm = fb.group({
      searchGroup: fb.group({
        titleId: ["", Validators.required],
        contentId: ["", Validators.required],
      })
    });
    this.searchNominationCastForm = fb.group({
      searchGroup: fb.group({
        castId: ["", Validators.required],
        castName: ["", Validators.required],
      })
    });

  }

  ngOnInit(): void {
    this.sharedService.showNavBar();
    this.fetchAllAwards();
  }

  private fetchAllAwards() {
    this.awardCategoriesService.listAllAwards().subscribe(data => {
      this.awardNames = data;
      this.populateAwardCategories(data[0])
    });
  }

  populateAwardCategories(award) {
    this.selectedAward = award;
    this.selectedCategory = []
    this.showNominations = false;
    this.awardCategoriesService.listAwardCategories(award.name).subscribe(data => {
      this.awardCategories = data;
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      this.downloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });

    // this.populateNominations(this.awardCategories)
  }

  populateAwardCategory(category) {
    this.currentAwardCategory = category;

    if (this.currentAwardCategory.nominationType == "content") {
      this.searchContent();
    }
    else {
      this.searchCast();
    }
    /* this.awardCategories.filter(x => {
      if (x.id == id) {
        nomination = x;
      }
    }) */
    /*     this.selectedCategory = nomination.nominations;
        this.showNominations = true;
        this.createNominations = false; */
  }

  createNominationForm() {
    this.createNominations = true;
    this.showNominations = false;
    this.currentAward = this.awardNames[0];
    this.currentAwardCategory = {
      awardId: this.currentAward.id,
      awardName: this.currentAward.name,
      slug: null,
      id: null,
      imageAssetUrl: "",
      videoAssetUrl: "",
      name: "",
      nominationType: "",
      nominations: []
    }
    this.selectedContentItems = []
    this.selectedCastItems = []
  }

  searchContent() {
    this.searchMovieSeries = true;
    if (this.currentAwardCategory.nominations.length > 0) {
      this.selectedContentItems = []
      this.currentAwardCategory.nominations.forEach(item => {
        const contentItem: Nomination = {
          nominationId: item.nominationId,
          imdbId: item.imdbId,
          title: item.title,
          year: item.year,
          slug: item.slug,
          genres: item.genres,
          imageAssetUrl: item.imageAssetUrl,
          videoAssetUrl: item.videoAssetUrl,
          nominatedFor: item.nominatedFor,
          nominated: item.nominated,
          winner: item.winner
        }
        this.selectedContentItems.push(contentItem)
      });
    }


  }
  searchCast() {
    this.searchMovieSeries = false;
    if (this.currentAwardCategory.nominations.length > 0) {
      this.selectedCastItems = [];
      this.currentAwardCategory.nominations.forEach(item => {
        const castItem: Nomination = {
          nominationId: item.nominationId,
          imdbId: item.imdbId,
          title: item.title,
          slug: item.slug,
          year: item.year,
          genres: item.genres,
          imageAssetUrl: item.imageAssetUrl,
          videoAssetUrl: item.videoAssetUrl,
          nominatedFor: item.nominatedFor,
          nominated: item.nominated,
          winner: item.winner
        }
        this.selectedCastItems.push(castItem)
      });
    }
  }

  /* Content Search */
  async onChangeContentSearch(keyword: any) {
    if (keyword != null) {
      // this.isLoading = true;
      this.searchService.searchMoviesSeries(keyword).subscribe(
        (res) => {
          this.searchContentData = res;
          // this.isLoading = false;
        },
        (err) => {
          this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the Movie Details. Please Try Again");
          // this.isLoading = false;
        }
      );
    }
    else {
      this.searchContentData = [];
      // this.isLoading = false;
    }

  }

  removeItemFromContentFeed(imdbId: string) {
    const index = this.selectedContentItems.findIndex(x => x.imdbId === imdbId);
    this.selectedContentItems.splice(index, 1);
  }

  addContentToFeed(item) {
    this.selectedContentIndex = -1;
    this.searchService.searchContentData(item.id).subscribe((res) => {
      this.selectedContentItems.unshift({
        nominationId: item.id,
        imdbId: item.imdbId,
        title: item.title,
        year: item.year,
        slug: item.slug,
        genres: item.genres,
        imageAssetUrl: res.posterPath,
        videoAssetUrl: res.trailerUrl,
        nominatedFor: item.nominatedFor,
        nominated: item.nominated,
        winner: item.winner
      });
    })

  }

  closedContentEventApi() {
    /* if (this.searchNominationContentForm.value.searchGroup.titleId.id == undefined) {
      this.searchNominationCastForm.get("searchGroup").get("contentId").setValue("");
    } */
  }

  /* Cast Search */


  async onChangeCastSearch(keyword: any) {
    if (keyword != null) {
      keyword = keyword.toLowerCase()
      this.isLoading = true;
      this.searchService.searchCast(keyword).subscribe(
        (res) => {
          this.searchCastData = res;
          this.isLoading = false;
        },
        (err) => {
          this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the Movie Details. Please Try Again");
          this.isLoading = false;
        }
      );
    }
    else {
      this.searchCastData = [];
      this.isLoading = false;
    }
  }


  addCastToFeed(item) {
    this.selectedCastIndex = -1;
    this.selectedCastItems.unshift({
      nominationId: item.id,
      imdbId: item.imdbNameId,
      title: item.name,
      year: item.birthYear,
      genres: item.profession,
      slug: item.slug,
      imageAssetUrl: item.profileImageUrl,
      videoAssetUrl: item.trailerUrl,
      nominatedFor: item.nominatedFor,
      nominated: item.nominated,
      winner: item.winner
    });
    //  this.selectedContentTemplate.imdbContentIds.unshift(item.imdbId);
  }

  removeItemFromCastFeed(imdbId) {
    const index = this.selectedCastItems.findIndex(x => x.imdbId === imdbId);
    this.selectedCastItems.splice(index, 1);
  }

  closedCastEventApi() {
    if (this.searchNominationCastForm.value.searchGroup.castId.id == undefined) {
      this.searchNominationCastForm.get("searchGroup").get("castId").setValue("");
    }
  }

  focusedEventApi(e: any) {
    // Fetch API data on Load
    this.onChangeContentSearch(null);
    this.onChangeCastSearch(null);
  }


  openApiPanel(e: { stopPropagation: () => void; }): void {
    e.stopPropagation();
    this.ngAutocompleteApi.open();
  }

  closeApiPanel(e: { stopPropagation: () => void; }): void {
    e.stopPropagation();
    this.ngAutocompleteApi.close();
  }

  focusApiPanel(e: { stopPropagation: () => void; }): void {
    e.stopPropagation();
    this.ngAutocompleteApi.focus();
  }

  handleCategoryFileInput(file: FileList) {
    this.categoryImageToUpload = file.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.categoryImageUrl = event.target.result;
    }
    reader.readAsDataURL(this.categoryImageToUpload);
  }

  uploadCategoryImage() {

    //TODO change this.selectedAward.slug to shortName
    let categorySlug = this.currentAwardCategory.name.replace(/\s+/g, '-').toLowerCase();
    let path: string = "filmfare/" + this.selectedAward.slug + "/" + categorySlug + "/asset/" + categorySlug

    let file = this.categoryImageToUpload;

    this.awardCategoriesService.uploadImage(file, path).subscribe(data => {
      this.currentAwardCategory.imageAssetUrl = data.uploadedPath
    }, (err) => {
    })
    // this.currentAwardCategory.imageAssetUrl = "https://artifacts.flyxit.com/" + path;
    this.categoryImageUrl = null
  }

  handleContentFileInput(file: FileList) {
    this.contentImageToUpload = file.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.contentImageUrl = event.target.result;
    }
    reader.readAsDataURL(this.contentImageToUpload);
  }

  uploadContentImage() {

    //TODO change this.selectedAward.slug to shortName
    let categorySlug = this.currentAwardCategory.name.replace(/\s+/g, '-').toLowerCase();
    let path: string = "filmfare/" + this.selectedAward.slug + "/" + categorySlug + "/asset/" + this.currentSelectedContent.imdbId

    let file = this.contentImageToUpload;
    this.awardCategoriesService.uploadImage(file, path).subscribe(data => {
      this.currentSelectedContent.imageAssetUrl = data.uploadedPath
    }, (err) => {
    })
    // this.currentSelectedContent.imageAssetUrl = "https://artifacts.flyxit.com/" + path
    this.contentImageUrl = null
  }

  handleCastFileInput(file: FileList) {
    this.castImageToUpload = file.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.castImageUrl = event.target.result;
    }
    reader.readAsDataURL(this.castImageToUpload);
  }

  uploadCastImage() {

    //TODO change this.selectedAward.slug to shortName
    let categorySlug = this.currentAwardCategory.name.replace(/\s+/g, '-').toLowerCase();
    let path: string = "filmfare/" + this.selectedAward.slug + "/" + categorySlug + "/asset/" + this.currentSelectedCast.imdbId
    let file = this.castImageToUpload;
    this.awardCategoriesService.uploadImage(file, path).subscribe(data => {
      this.currentSelectedCast.imageAssetUrl = data.uploadedPath
    }, (err) => {
    })
    // this.currentSelectedCast.imageAssetUrl = this.awardCategoriesService.uploadImage(file, path)
    this.castImageUrl = null
  }

  async onChangeNominationContentSearch(keyword: any) {
    if (keyword != null) {
      // this.isLoading = true;
      this.searchService.searchMoviesSeries(keyword).subscribe(
        (res) => {
          this.searchNominationContentData = res;
          // this.isLoading = false;
        },
        (err) => {
          this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the Movie Details. Please Try Again");
          // this.isLoading = false;
        }
      );
    }
    else {
      this.searchNominationContentData = [];
      // this.isLoading = false;
    }

  }

  addNominationContentToFeed(item, content) {

    content.nominatedFor = {
      id: item.id,
      imdbId: item.imdbId,
      title: item.title,
      slug: item.slug,
      year: item.year,
      genres: item.genres,
      imageAssetUrl: 'https://artifacts.flyxit.com/content/poster/' + item.imdbId,
      videoAssetUrl: item.trailerUrl,
      contentType: "content",
      nominated: item.nominated,
      winner: item.winner
    };
  }

  removeNominatedForItem() {
    this.selectedNominatedForItem = null
  }

  submitCastNomination() {
    this.showCurrentSelectedCastPopUp = false;
  }

  // 
  castSelectionPopup(cast) {
    this.currentSelectedCast = cast;
    this.showCurrentSelectedCastPopUp = true;

  }

  contentSelectionPopup(content) {
    this.currentSelectedContent = content;
    this.showCurrentSelectedContentPopUp = true;
  }

  saveContentNomination() {
    const contentNominationPayload = [];
    this.selectedContentItems.forEach(item => {
      const nomination = {
        nominationId: item.nominationId,
        imdbId: item.imdbId,
        title: item.title,
        year: item.year,
        genres: item.genres,
        imageAssetUrl: item.imageAssetUrl,
        videoAssetUrl: item.videoAssetUrl,
        nominatedFor: item.nominatedFor,
        nominated: item.nominated,
        winner: item.winner
      }
      contentNominationPayload.push(nomination)
    })

    const contentNomination = {
      id: this.currentAwardCategory.id,
      name: this.currentAwardCategory.name,
      slug: this.currentAwardCategory.slug,
      awardId: this.currentAwardCategory.awardId,
      awardName: this.currentAwardCategory.awardName,
      imageAssetUrl: this.currentAwardCategory.imageAssetUrl,
      videoAssetUrl: this.currentAwardCategory.videoAssetUrl,
      nominationType: "content",
      nominations: contentNominationPayload
    };

    this.awardCategoriesService.updateNomination(contentNomination)
  }

  saveCastNomination() {
    const castNominationPayload = [];

    this.selectedCastItems.forEach(item => {
      const nomination = {
        nominationId: item.nominationId,
        imdbId: item.imdbId,
        title: item.title,
        year: item.year,
        genres: [],
        imageAssetUrl: item.imageAssetUrl,
        videoAssetUrl: item.videoAssetUrl,
        nominatedFor: item.nominatedFor,
        nominated: item.nominated,
        winner: item.winner
      }
      castNominationPayload.push(nomination)
    })

    const castNomination = {
      id: this.currentAwardCategory.id,
      name: this.currentAwardCategory.name,
      awardId: this.currentAwardCategory.awardId,
      slug: this.currentAwardCategory.slug,
      awardName: this.currentAwardCategory.awardName,
      imageAssetUrl: this.currentAwardCategory.imageAssetUrl,
      videoAssetUrl: this.currentAwardCategory.videoAssetUrl,
      nominationType: "cast",
      nominations: castNominationPayload
    };

    this.awardCategoriesService.updateNomination(castNomination)
  }


}
