import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AvailableOnService } from '../Services/available-on.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
 })
export class RecentListResolver implements Resolve<any> {

    constructor(private AvailableOn:AvailableOnService){

    }
    resolve(route: ActivatedRouteSnapshot) {
       return this.AvailableOn.recentMoviesSeries();
    }
}
