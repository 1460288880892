export class Platform {
    countryCode: String;
    type: String;
    name: String;
    url: String;
    offerType: String;
    deliveryMethod: String;
    price: number;
    currency: String;
    quality: String
}