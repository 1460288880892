import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firebaseAuth } from '../config/firebase-init';

@Injectable({
  providedIn: 'root'
})
export class LoginSignupService {
  userScopes: string[] = [];

  constructor(private http: HttpClient) { }

  verifyOTP(data) {
    return this.http.post<any>(environment.yavinUserUrl, data);
  }

  verifyAdmin() {
    return this.http.get<any>(environment.yavinUserUrl);
  }

  findUserPermissions() {
    return this.http.get<any>(environment.adminBaseMetricsUrl+"/user-dashboard-profile");
  }

  hasPermission(scope): Promise<boolean> {
    if (this.userScopes.length == 0) {
      return this.findUserPermissions().toPromise().then((res) => {
        this.userScopes = res.scopes;
        return this.userScopes.includes(scope);
      });
    }
    return Promise.resolve(this.userScopes.includes(scope));
  }

  getUserScopes(): Promise<string[]> {
    if (this.userScopes.length == 0) {
      return this.findUserPermissions().toPromise().then((res) => {
        this.userScopes = res.scopes;
        return this.userScopes;
      });
    }
    return Promise.resolve(this.userScopes);
  }

  fetchNewIdToken(): Promise<string|null> {
    return new Promise((resolve, reject) => {
      firebaseAuth.onIdTokenChanged(async (user) => {
        if (user) {
          const newIdToken = await user.getIdToken();
          resolve(newIdToken);
        } else {
          resolve(null);
        }
      });
    });
  }
}
