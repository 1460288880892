import { CookieService } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { firebaseFirestoreDb } from '../config/firebase-init';
import { ServiceSnackbarService } from './service-snackbar.service';
import { USER_SESSION } from 'src/app/config/global-constants';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private http: HttpClient,
    public snackBar: ServiceSnackbarService,
    private cookieService: CookieService,
  ) {
  }

  sendEmail(emailAudienceSegment: any):Observable<any>{
    const api = environment.adminServiceBaseUrl + `/utils/send-email`;
    return this.http.post<any>(api, emailAudienceSegment);
  }
}
