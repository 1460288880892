import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrendingFeedsService {
  http: HttpClient;
  constructor(http: HttpClient) {
    this.http = http;
  }

  public fetchAllSearchTemplates(): Observable<any[]> {
    return this.http.get<any[]>(environment.searchBaseUrl + "/search-templates");
  }

  public updatedFeeds(feedList: any[]): Observable<any[]> {
    return this.http.post<any>(environment.searchBaseUrl + "/search-templates", feedList);
  }
}    