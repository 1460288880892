import { ServiceSnackbarService } from './../../Services/service-snackbar.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AvailableOnService } from 'src/app/Services/available-on.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MovieDetails } from '../../models/MovieDetails';
import { SharedService } from 'src/app/Services/shared.service';
import { TrendingFeedsService } from 'src/app/Services/trending-feeds.service';

import { AvailableOn } from "../../models/AvailableOn";
import { Platform } from "../../models/Platform";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";
import { AddNewDialogComponent } from "../../components/add-new-dialog/add-new-dialog.component";
import { SearchItem } from '../../models/SearchItem';
import { AvailableOnList } from '../../models/AvailableOnList';

@Component({
  selector: 'trending-feeds',
  templateUrl: './trending-feeds.component.html',
  styleUrls: ['./trending-feeds.component.css']
})
export class TrendingFeedsComponent implements OnInit {

    public availableOn: AvailableOn;
    public platform: Platform;

    availableOnForm: FormGroup;
    showMovie: SearchItem;
    countries = [];
    isLoading: boolean = false;
    items: any;
    regexStr = /'/g;
    itemsPerSlide = 3;
    noWrap = false;
    public keyword = "title";
    slidesChangeMessage = "";
    selectedIndex: number;
    recentItemSlide: any;
    selectedCountryAvailableData: boolean = true;
    expandStatus: boolean = false;
    selectedMovieAvailableData: boolean = true;
    searchContentId: string = "";
    @ViewChild("ngAutocompleteApi", { static: true }) ngAutocompleteApi;
    showCarousel: boolean = true;
    showMovieDetails = false;
    public historyHeading: string = "Recently selected";


    movieDetails: MovieDetails;
    availableOnList: AvailableOn[] = [];
    chooseStream: any;
    searchTemplates: any;
    selectedTemplateItems: MovieDetails[];
    feedsToBeUpdated: Set<string> = new Set();
    selectedTemplate: any = '';

  constructor(private service: AvailableOnService,
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
    private router: Router,
        public sharedService: SharedService,
    private trendingFeedsService: TrendingFeedsService,
    private snackBar: ServiceSnackbarService
  ) {
      this.availableOnForm = fb.group({
          searchGroup: fb.group({
              titleId: ["", Validators.required],
              contentId: ["", Validators.required],
          }),
          country: new FormControl(["", Validators.required]),
      });

      this.availableOn = new AvailableOn();
      this.platform = new Platform();
  }

  ngOnInit() {
    this.sharedService.showNavBar();
    this.recentItemSlide = this.route.snapshot.data.recentList;

    this.trendingFeedsService.fetchAllSearchTemplates().subscribe((res) => {
      this.searchTemplates = res;
      this.selectTemplate(res[0]);
    }, (err) => {
        this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying data. Please Try Again");
    });
  }

  selectTemplate(selectedSearchTemplate: any) {
    this.selectedTemplate = selectedSearchTemplate;
    this.selectedTemplateItems = this.searchTemplates.filter(
      x=> x.name === selectedSearchTemplate.name && x.country === selectedSearchTemplate.country)[0].contentList;
      
      selectedSearchTemplate.selected = true;

      const unselectedTemplates = this.searchTemplates.filter(
      x=> x.name !== selectedSearchTemplate.name || x.country !== selectedSearchTemplate.country);

      unselectedTemplates.forEach(x => {
        x.selected = false;
      });
  }

  removeItemFromFeed(imdbId: string) {
    const index = this.selectedTemplateItems.findIndex(x => x.imdbId === imdbId);
    this.selectedTemplateItems.splice(index, 1);

    const imdbIdIndex = this.selectedTemplate.imdbContentIds.findIndex(x => x === imdbId);
    this.selectedTemplate.imdbContentIds.splice(index, 1);

    this.feedsToBeUpdated.add(this.selectedTemplate.name + '('+this.selectedTemplate.country+')');
  }

  addContentToFeed(item) {
    this.selectedIndex = -1;
    this.selectedTemplateItems.unshift(item);
    this.selectedTemplate.imdbContentIds.unshift(item.imdbId);
    this.feedsToBeUpdated.add(this.selectedTemplate.name + '('+this.selectedTemplate.country+')');
  }

  updateFeed() {
    const updatedFeeds = Array.from(this.feedsToBeUpdated).join(', ');

    if(confirm("The following feeds are going to be updated:  " + updatedFeeds)) {
      const updatePayload = [];

      this.searchTemplates.forEach(x => {
        this.feedsToBeUpdated.forEach(y => {
          if (x.name + '('+x.country+')' === y){
            updatePayload.push(x);
          }
        })
      });
      this.trendingFeedsService.updatedFeeds(updatePayload).subscribe((res) => {
        this.searchTemplates = res;
        this.selectTemplate(res[0]);
      }, (err) => {
          this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the data. Please Try Again")
      });
    }
  }

    async onChangeSearch(keyword: any) {
        if (keyword != null) {
            this.isLoading = true;
            this.service.searchMoviesSeries(keyword).subscribe(
                (res) => {
                  this.items = res;
                  this.isLoading = false;
                },
                (err) => {
                  this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the content. Please Try Again");
                  this.isLoading = false;
                }
            );
        }
    }

  focusedEventApi(e: any) {
        // Fetch API data on Load
        this.onChangeSearch(null);
    }

  closedEventApi() {
        if (this.availableOnForm.value.searchGroup.titleId.id == undefined) {
            this.availableOnForm.get("searchGroup").get("titleId").setValue("");
        }
    }

  openApiPanel(e: { stopPropagation: () => void; }): void {
        e.stopPropagation();
        this.ngAutocompleteApi.open();
    }

  closeApiPanel(e: { stopPropagation: () => void; }): void {
        e.stopPropagation();
        this.ngAutocompleteApi.close();
    }

  focusApiPanel(e: { stopPropagation: () => void; }): void {
        e.stopPropagation();
        this.ngAutocompleteApi.focus();
    }

    /**
     * End of API
     */

    /* horizontal scrolling  methods start */

    slideConfig = {
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: "<div class='nav-btn next-slide'></div>",
        prevArrow: "<div class='nav-btn prev-slide'></div>",
        dots: true,
        infinite: false,
    };

}
