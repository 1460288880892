import dayjs from 'dayjs';
import { CookieService } from 'ngx-cookie';
import {
  firebaseInstance,
  firebaseAuth,
  firebaseFacebookAuthProvider,
  firebaseGoogleAuthProvider,
} from "./../../config/firebase-init";
import { Component, OnInit, NgZone } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  SearchCountryField,
  TooltipLabel,
  CountryISO,
} from "ngx-intl-tel-input";
import { GlobalObjService } from "src/app/Services/global-obj.service";
import { LoginSignupService } from "src/app/Services/login-signup.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { SharedService } from "../../Services/shared.service";
import { USER_SESSION } from 'src/app/config/global-constants';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  displayName: string;
  adminDetails;
  windowRef: any;
  verificationCode: string;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });
  user: any;
  showOTP = false;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "50px",
      height: "50px",
    },
  };

  constructor(
    private win: GlobalObjService,
    private auth: LoginSignupService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private cookieService: CookieService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.sharedService.hideNavBar();
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebaseInstance.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: function (response) {
          // this.login()
        },
      }
    );

    const idToken = this.cookieService.get(USER_SESSION.tokenKey);
    if (idToken) {
      this.auth.getUserScopes().then((scopes) => {
        if (scopes && scopes.length > 0) {
          this.router.navigate([scopes[0]]);
        } else {
          localStorage.clear();
          this.router.navigate(["/login"]);
          this.sharedService.hideNavBar();
        }
      });
    }
  }

  number: "";
  onSubmit(f: any) {
    if (f.form.value.phone == undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Please enter valid phone number.",
        showConfirmButton: false,
        timer: 1500,
      });
      this.spinner.hide();
      return;
    }
    this.number = f.form.value.phone.e164Number;

    this.login();
  }

  loginWithFacebook() {
    firebaseAuth
      .signInWithPopup(firebaseFacebookAuthProvider)
      .then((result: any) => {
        this.zone.run(() => {
          this.handleFirebaseUserLoginResult(result);
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          // timer: 1500
        });
      });
  }

  loginWithGoogle() {
    firebaseAuth
      .signInWithPopup(firebaseGoogleAuthProvider)
      .then((result: any) => {
        this.zone.run(() => {
          this.handleFirebaseUserLoginResult(result);
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          // timer: 1500
        });
      });
  }

  login() {
    if (this.phoneForm.value.phone) {
      const appVerifier = this.windowRef.recaptchaVerifier;
      const num = this.phoneForm.value.phone.e164Number;

      this.spinner.show;
      firebaseAuth
        .signInWithPhoneNumber(num, appVerifier)
        .then((result) => {
          this.zone.run(() => {
            this.windowRef.confirmationResult = result;
            this.showOTP = true;
          });
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.message,
            showConfirmButton: false,
            // timer: 1500
          });
        })
        .finally(() => {
          this.spinner.hide;
        });
    }
  }

  onOtpChange(otp) {
    this.verificationCode = otp;
  }

  handleFirebaseUserLoginResult(result) {
    this.user = result.user;
    localStorage.setItem(USER_SESSION.userKey, this.user.uid);
    this.cookieService.put(USER_SESSION.tokenKey, result.user.ya, {
      expires: dayjs().add(60, 'minute').toDate(),
    });
    this.auth.getUserScopes().then((scopes) => {
      if (scopes && scopes.length > 0) {
        this.router.navigate([scopes[0]]);
      } else {
        localStorage.clear();
        this.router.navigate(["/login"]);
        alert("You are not authorised to use the application");
        this.sharedService.hideNavBar();
        location.reload();
      }
    });
  }

  verifyLoginCode() {
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then((result) => {
        this.handleFirebaseUserLoginResult(result);
      })
      .catch((error) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.message,
          showConfirmButton: false,
          // timer: 1500
        });
      });
  }
}
