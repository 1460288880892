import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { LoginComponent } from './pages/login/login.component';
import { AvailableOnComponent } from './pages/available-on/available-on.component';
import { TrendingFeedsComponent } from './pages/trending-feeds/trending-feeds.component'
import { MetricsDashboardComponent } from './pages/metrics-dashboard/metrics-dashboard.component';
import { AwardCategoriesComponent } from './pages/award-categories/award-categories.component';
import { BackstageComponent } from './pages/backstage/backstage.component';
import { AuthGuardService } from './Services/auth-guard-service';
import { RecentListResolver } from './Resolver/recent-list.resolver';
import {UsersComponent} from "./components/users/users.component";
import {UtilsComponent} from "./components/utils/utils.component";

const routes: Routes = [
  { path: 'autocomplete', component: AutocompleteComponent },
  { path: 'login', component: LoginComponent },
  {
    path: '',
    redirectTo: '/content',
    pathMatch: 'full'
  },
  {
    path: 'content', component: AvailableOnComponent,
    resolve: {
      recentList:RecentListResolver
    },
    canActivate: [AuthGuardService]
  },
  { path: 'trending-feeds', component: TrendingFeedsComponent, canActivate: [AuthGuardService] },
  { path: 'metrics', component: MetricsDashboardComponent, canActivate: [AuthGuardService]},
  { path: 'backstage', component: BackstageComponent, canActivate: [AuthGuardService]},
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService]},
  { path: 'utils', component: UtilsComponent, canActivate: [AuthGuardService]},
  { path: 'award-categories', component: AwardCategoriesComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: 'content' }
  // { path: 'navbar', component: NavbarComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
