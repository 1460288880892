import {animate, state, style, transition, trigger} from '@angular/animations';
import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ServiceSnackbarService} from 'src/app/Services/service-snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {SharedService} from '../../../Services/shared.service';
import {UtilityService} from '../../../Services/utility.service';

export interface DialogData {
  name: string;
  points: number;
  pointsRedeemed: number;
}

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmailComponent implements OnInit {
  redeemRequests: any[];
  data: SafeHtml;
  template = `<!DOCTYPE html>
  <html>
    <head>
    <title>Page Title</title>
    </head>
    <body style="background-color:#161620">
      <span>
      <a href="https://app.flyx.me">
      <img style="width:100%" src="https://artifacts.flyxit.com/welcome_email"
      alt="Flyx - Stop Searching, Start Watching"/>
      </a>
      </span>
      <div style="text-align:center; padding:5px; background-color: white;">
      <a href="$unsubscribeLink">Unsubscribe</a>
      </div>
    </body>
  </html>
`;
  audience: any[] = [
    {
      title: 'Enter user emails',
      value: 'UserEmails'
    },
    {
      title: 'All Users',
      value: 'AllUsers'
    },
    {
      title: 'Select countries',
      value: 'Countries'
    },
    {
      title: 'All Android Users',
      value: 'AndroidUsers'
    },
    {
      title: 'All iOS Users',
      value: 'IOSUsers'
    }
  ];
  selectedAudience: string;
  emails: string;
  selectedCountries: string[] = [];
  subject = 'Hi $name';
  cc: string;
  bcc: string;
  from = 'Flyx <noreply@flyx.me>';
  emailType: string[] = ['MARKETING', 'PROMOTIONAL', 'APP_UPDATE', 'GENERAL']
  selectedEmailType: string;
  confirmation: string;

  constructor(
    private utilityService: UtilityService,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public sharedService: SharedService,
  ) {
  }

  ngOnInit(): void {
    this.sharedService.showNavBar();
    this.handleUpdates();
  }

  handleUpdates() {
    this.data = this.sanitizer.bypassSecurityTrustHtml(this.template);
  }

  countriesChecked(country: string) {
    if (!this.selectedCountries.includes(country)) {
      this.selectedCountries.push(country);
    } else {
      const index = this.selectedCountries.indexOf(country);
      if (index > -1) {
        this.selectedCountries.splice(index, 1);
      }
    }
    console.log(this.selectedCountries);
  }

  sendEmails() {
    // adding validations
    if (!this.selectedEmailType || !this.selectedAudience) {
      alert('Email type or Audience segment is not selected')
      return false;
    }

    if (this.selectedAudience === 'UserEmails' && (!this.emails || this.emails.length === 0)){
      alert('You need to enter emails for users.')
      return false;
    }

    if (this.selectedAudience === 'Countries' && this.selectedCountries.length === 0){
      alert('You need to select a country.')
      return false;
    }

    const emails = this.emails?.split(';')
    const toEmail: any[] = [];
    if (this.emails && this.emails.length > 0) {
      emails.forEach(email => {
        const item = email.split('|');
        toEmail.push({name: item[0], email: item[1]});
      })
    }

    const emailAudienceSegment = {
      subject: this.subject,
      body: this.template,
      fromEmailAddress: this.from,
      toEmailAddresses: toEmail.length > 0 ? [] : emails,
      toEmails: toEmail,
      ccEmailAddresses: this.cc?.split(';'),
      bccEmailAddresses: this.bcc?.split(';'),
      countries: this.selectedCountries,
      segment: this.selectedAudience,
      emailType: this.selectedEmailType
    }

    this.utilityService.sendEmail(emailAudienceSegment).subscribe((res) => {
      this.snackBar.open('Successfully queued job for sending emails');
      // tslint:disable-next-line:no-unused-expression
    }), (err) => {
      console.log("error")
      this.snackBar.open('Failed registering job for sending emails');
    }
  }
}
