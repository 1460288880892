import { environment } from './../../environments/environment';
import firebase from 'firebase/app';
// eslint-disable-next-line import/no-duplicates
import 'firebase/auth';
// eslint-disable-next-line import/no-duplicates
import 'firebase/database';
// eslint-disable-next-line import/no-duplicates
import 'firebase/analytics';

import 'firebase/firestore';

let localFirebaseAuth = null;
let localFirebaseAnalytics = null;
let localFirebaseGoogleAuthProvider = null;
let localFirebaseFacebookAuthProvider = null;
let localFirebaseTwitterAuthProvider = null;
if (!firebase.apps.length) {
  // Initialize Firebase
  firebase.initializeApp(environment.firebase);
  localFirebaseAuth = firebase.auth();
  localFirebaseAnalytics = firebase.analytics();
  localFirebaseGoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
  localFirebaseFacebookAuthProvider = new firebase.auth.FacebookAuthProvider();
  localFirebaseTwitterAuthProvider = new firebase.auth.TwitterAuthProvider();
}

export const firebaseInstance = firebase;
export const firebaseAuth = localFirebaseAuth;
export const firebaseAnalytics = localFirebaseAnalytics;
export const firebaseFirestoreDb = firebase.firestore();
export const firebaseGoogleAuthProvider = localFirebaseGoogleAuthProvider;
export const firebaseFacebookAuthProvider = localFirebaseFacebookAuthProvider;
export const firebaseTwitterAuthProvider = localFirebaseTwitterAuthProvider;