import {
    Component,
    OnInit,
    ViewChild
} from "@angular/core";
import { AvailableOnService } from "../../Services/available-on.service";
import { AvailableOn } from "../../models/AvailableOn";
import { Platform } from "../../models/Platform";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";
import { AddNewDialogComponent } from "../../components/add-new-dialog/add-new-dialog.component";
import { SharedService } from '../../Services/shared.service';
import { SearchItem } from '../../models/SearchItem';
import { AvailableOnList } from '../../models/AvailableOnList';
import { MovieDetails } from '../../models/MovieDetails';
import { ServiceSnackbarService } from '../../Services/service-snackbar.service';

@Component({
    selector: "app-available-on",
    templateUrl: "./available-on.component.html",
    styleUrls: ["./available-on.component.css"],
})
export class AvailableOnComponent implements OnInit {
    public availableOn: AvailableOn;
    public platform: Platform;

    availableOnForm: FormGroup;
    showMovie: SearchItem;
    countries = [];
    isLoading: boolean = false;
    items: any;
    regexStr = /'/g;
    itemsPerSlide = 4;
    noWrap = false;
    public placeholder: string = "Find movies and shows";
    public keyword = "title";
    public historyHeading: string = "Recently selected";
    selectedIndex: number;
    recentItemSlide: any;
    selectedCountryAvailableData: boolean = true;
    expandStatus: boolean = false;
    selectedMovieAvailableData: boolean = true;
    searchContentId: string = "";
    @ViewChild("ngAutocompleteApi", { static: true }) ngAutocompleteApi;
    showCarousel: boolean = true;
    showMovieDetails = false

    movieDetails: MovieDetails;
    availableOnList: AvailableOn[] = [];
    chooseStream: any;
    contentSearchData: MovieDetails;

    constructor(
        private service: AvailableOnService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        public sharedService: SharedService,
        public snackBar: ServiceSnackbarService,
    ) {
        this.availableOnForm = fb.group({
            searchGroup: fb.group({
                titleId: ["", Validators.required],
                contentId: ["", Validators.required],
            }),
            addForm: this.fb.array([]),
            country: new FormControl(["", Validators.required]),
        });

        this.availableOn = new AvailableOn();
        this.platform = new Platform();
    }

    ngOnInit() {
        this.sharedService.showNavBar();
        this.recentItemSlide = this.route.snapshot.data.recentList;
        this.populateCountries();
    }

    populateCountries() {
        this.service.fetchCountryList().subscribe((res) => {
            this.countries.push(res);
        }, (err) => {
                this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the countries. Please Try Again");
        });

    }

    openDialog(index?: number, contentProvider?: String) {
        let selectedAvailableOn = new AvailableOnList();
        selectedAvailableOn.contentId = this.availableOnForm.get("searchGroup").get("contentId").value
        selectedAvailableOn.availableOnList = this.availableOnList
        selectedAvailableOn.countryCode = this.selectedcountry;
        selectedAvailableOn.selectedPlatformIndex = index;
        selectedAvailableOn.selectedContentProvider = contentProvider;
        const dialogRef = this.dialog.open(AddNewDialogComponent, {
            height: "800px",
            width: "1000px",
            data: selectedAvailableOn,
            disableClose: true
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result != true){
                this.availableOnList = result;
                this.snackBar.open("Data has been saved successfully.");
            }
            // this.populateAvailableOnData(this.searchContentId, this.selectedcountry)
        },
            (err) => {
                alert("Error thrown. Please add the data again")
            });
    }

    async onChangeSearch(keyword: any) {
        if (keyword != null) {
            this.isLoading = true;
            this.service.searchMoviesSeries(keyword).subscribe(
                (res) => {
                    this.items = res;
                    this.isLoading = false;
                },
                (err) => {
                    this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the Movie Details. Please Try Again");
                    this.isLoading = false;
                }
            );
        }
    }

    selectedcountry: string = "Select Country";

    changeCountry(newCountry: string) {
        this.availableOnForm.get("country").setValue(newCountry);
        this.selectedcountry = newCountry;
        this.selectedCountryAvailableData = false;
        this.searchContentId = this.availableOnForm.get("searchGroup").get("contentId").value
        this.populateAvailableOnData(this.searchContentId, this.selectedcountry)
    }

    populateAvailableOnData(contentId: string, countryId: string) {
        this.service.fetchavailableOnData(contentId, countryId).subscribe((res) => {
            this.availableOnList = res;
        }, (err) => {
                this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the available on data. Please Try Again")
        });
    }

    /**
    *   Carousel select function.
    */
    selectMovieSeries(index: number) {
        this.selectedCountryAvailableData = true;
        this.selectedIndex = index;
        this.availableOnForm.get("searchGroup").get("contentId").setValue(this.recentItemSlide[index].id);
        this.availableOnForm.get("searchGroup").get("titleId").setValue(this.recentItemSlide[index].title);
        this.selectedMovieAvailableData = false;
        this.showCarousel = false;
        this.showMovieDetails = true;
        this.addShowMovie(this.recentItemSlide[index]);
    }

    showCarouselBack() {
        this.showCarousel = true;
        this.showMovieDetails = false;
        this.expandStatus = false;
    }

    /**
     * Autocomplete search functions.
     */

    async selectEvent(item) {
        this.populateSearchContent(item.id);
        this.addShowMovie(item);
        this.selectedCountryAvailableData = true;
        this.availableOnForm.get("searchGroup").get("titleId").setValue(item.title);
        this.availableOnForm.get("searchGroup").get("contentId").setValue(item.id);
        this.selectedIndex = -1;
        this.selectedMovieAvailableData = false;
        this.showCarousel = false
        this.showMovieDetails = true
    }

    private populateSearchContent(id) {
        this.service.searchContentData(id).subscribe((res: MovieDetails) => {
            this.movieDetails = res;
        });
    }

    focusedEventApi(e: any) {
        // Fetch API data on Load
        this.onChangeSearch(null);
    }

    closedEventApi() {
        if (this.availableOnForm.value.searchGroup.titleId.id == undefined) {
            this.availableOnForm.get("searchGroup").get("titleId").setValue("");
        }
    }

    addShowMovie(item) {
        this.showMovie = {
            id: item.id,
            imdbId: item.imdbId,
            title: item.title,
            year: item.year,
            genres: item.genres,
            imdbRating: item.imdbRating,
            numVotes: item.numVotes,
            type: item.type,
            poster: 'https://artifacts.flyxit.com/content/poster/' + item.imdbId
        }
        this.movieDetails = item;
    }

    openApiPanel(e: { stopPropagation: () => void; }): void {
        e.stopPropagation();
        this.ngAutocompleteApi.open();
    }

    closeApiPanel(e: { stopPropagation: () => void; }): void {
        e.stopPropagation();
        this.ngAutocompleteApi.close();
    }

    focusApiPanel(e: { stopPropagation: () => void; }): void {
        e.stopPropagation();
        this.ngAutocompleteApi.focus();
    }

    /**
     * End of API
     */

    /* horizontal scrolling  methods start */

    slideConfig = {
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: "<div class='nav-btn next-slide'></div>",
        prevArrow: "<div class='nav-btn prev-slide'></div>",
        dots: true,
        infinite: false,
    };

    /* horizontal scrolling  methods end */
}
