import * as Highcharts from 'highcharts';

import { Component, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';
import HC_map from 'highcharts/modules/map';
import { MetricsDashboardService } from 'src/app/Services/metrics-dashboard.service';
import { Options } from 'highcharts';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';
import worldMap from '@highcharts/map-collection/custom/world-highres.geo.json';
HC_map(Highcharts);

@Component({
  selector: 'app-aggregated-metrics',
  templateUrl: './aggregated-metrics.component.html',
  styleUrls: ['./aggregated-metrics.component.css']
})
export class AggregatedMetricsComponent implements OnInit {

  usersCount: any;
  showCount: boolean = false;
  minDate: "2017-01-01";
  maxDate: Date;
  activeUsers;
  lastUpdatedAtTotalUsers: {};

  usersBySignUp: {}[];
  Highcharts: typeof Highcharts = Highcharts;
  refreshTotalUsers: boolean = false;
  endDate: Date;
  startDate: Date;

  countryChartOptions: Options = {};

  providerchartOptions = {};

  constructor(private service: MetricsDashboardService, public snackBar: ServiceSnackbarService, public datePipe: DatePipe) {

    this.maxDate = new Date();
  }

  ngOnInit(): void {
    this.populateTotalUsers();
    this.populateUsersbySignUp();
    this.populateUsersByCountry();
    this.prepareUsersByProviderChart();
    this.prepareUsersByCountryChart();
  }

  populateTotalUsers() {
    this.service.fetchTotalUsers(this.refreshTotalUsers).subscribe((res) => {
      this.usersCount = res;
      this.lastUpdatedAtTotalUsers = this.datePipe.transform(res.updatedAt, 'full')
    }, (err) => {
      this.snackBar.open("Error: " + err.statusText +
        "\n There was an error displaying the total number of users. Please refreshing the page");
    });
  }

  populateTotalActiveUsers() {
    let start: String = this.datePipe.transform(this.startDate, 'yyyy-MM-dd')
    let end: String = this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
    this.service.fetchActiveUsers(start, end).subscribe((res) => {
      this.activeUsers = res;
    }, (err) => {
      this.snackBar.open("Error: " + err.statusText +
        "\n There was an error displaying the total number of active users. Please refreshing the page");
    });
  }

  populateUsersByCountry() {
    let usersByCountry = [];
    this.service.fetchUsersByCountry().subscribe((res) => {
      let countryData = res.userCounts
      for (var i = 0; i < countryData.length; i++) {
        let country: String = countryData[i].country.toLowerCase();
        usersByCountry.push([country, countryData[i].userCount])
      }
      this.prepareUsersByCountryChart(usersByCountry);
    }, (err) => {
      this.snackBar.open("Error: " + err.statusText +
        "\n There was an error displaying the total number of users by country. Please refreshing the page");
    });
  }

  private populateUsersbySignUp() {
    this.service.fetchUsersbySignUp().subscribe((res) => {
      this.usersBySignUp = res.userCounts.map((x) => ({
        'name': x.provider,
        'y': x.userCount
      })
      );
      this.prepareUsersByProviderChart(this.usersBySignUp);
    }, (err) => {
      this.snackBar.open("Error: " + err.statusText +
        "\n There was an error displaying the total number of users. Please Try Again");
    });
  }

  prepareUsersByProviderChart(pieData?: any) {
    this.providerchartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: "Users by Signup Type"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Providers',
        colorByPoint: true,
        data: pieData
      }]
    };
  }

  prepareUsersByCountryChart(countryData?: any) {
    this.countryChartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        map: worldMap as any
      },
      title: {
        text: "Users by Country"
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          alignTo: "spacingBox"
        }
      },
      legend: {
        enabled: true
      },
      credits: {
        enabled: false
      },
      colorAxis: {
        min: 0
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      series: [
        {
          type: "map",
          name: "Users here",
          states: {
            hover: {
              color: "#40730e"
            }
          },
          dataLabels: {
            enabled: false,
            format: "{point.name}"
          },
          allAreas: false,
          data: countryData
        }]

    };
  }
}
