import { Injectable } from '@angular/core';
import { Platform } from '../models/Platform';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private isLoggedIn = false;
  private admin = false;
  platform: Platform[]

  setLoggedIn(val: boolean) {
    this.isLoggedIn = val;
  }

  getLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  getAdmin() {
    return this.admin;
  }

  setAdmin(val: boolean) {
    this.admin = val;
  }

  navBarVisible: boolean;

  hideNavBar() { this.navBarVisible = false; }

  showNavBar() { this.navBarVisible = true; }

  constructor() { }
}
