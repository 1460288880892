import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { SharedService } from 'src/app/Services/shared.service';
import { firebaseAuth } from 'src/app/config/firebase-init';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
	@Output() public sidenavToggle = new EventEmitter();

	constructor(
		private router: Router,
		public sharedService: SharedService,
		private cookieService: CookieService,
	) { }

	ngOnInit() {}
	
	logOutUser() {
		firebaseAuth.signOut().then(async () => { 
			localStorage.clear();
			this.cookieService.removeAll();
			this.router.navigate(["/login"]);
			this.sharedService.hideNavBar();
		});
	}

	public onToggleSidenav = () => {
		this.sidenavToggle.emit();
	}

}
