import { CookieService } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { firebaseFirestoreDb } from '../config/firebase-init';
import { ServiceSnackbarService } from './service-snackbar.service';
import { USER_SESSION } from 'src/app/config/global-constants';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,
    public snackBar: ServiceSnackbarService,
    private cookieService: CookieService,
  ) {
  }

  findAllPointsRedeemRequests(): Observable<any> {
    const api = environment.yavinBaseUrl + 'user-points/redeem-requests';
    return this.http.get<any>(api)
  }

  adminPayPoints(points: any, userId: any):Observable<any>{
    const api = environment.yavinBaseUrl + `user-points/redeem-request?points=${points}&userId=${userId}`;
    return this.http.post<any>(api, {});
  }

  searchUser(term: string):  Observable<any> {
    const api = environment.searchBaseUrl + `/person?name=${term}`;
    return this.http.get(api);
  }
}
