import {CookieService} from 'ngx-cookie';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {firebaseFirestoreDb} from '../config/firebase-init';
import {ServiceSnackbarService} from './service-snackbar.service';
import {USER_SESSION} from 'src/app/config/global-constants';

@Injectable({
  providedIn: 'root'
})
export class BackstageDashboardService {
  collectionName = `bakstageEvents`;

  constructor(
    private http: HttpClient,
    public snackBar: ServiceSnackbarService,
    private cookieService: CookieService,
  ) {
  }

  uploadEventImage(formData: any): Promise<any> {
    const token = this.cookieService.get(USER_SESSION.tokenKey);
    const headers = {
      'Authorization': token
    }

    return fetch(environment.backstage.uploadImage, {
      method: 'POST',
      body: formData,
      headers
    }).then((res) => res.json());
  }

  fetchActiveVoiceRooms(): Observable<any> {
    let api = environment.backstage.voiceRooms + "all-active?page=0&size=1000"
    return this.http.get<any>(api)
  }

  fetchRecordedVoiceRooms(page: number): Observable<any> {
    const api = `${environment.backstage.voiceRooms}getAllRecordedVoiceRooms?page=${page}&size=50&timeFilter=0`
    return this.http.get<any>(api)
  }

  searchVoiceRooms(term: string): Observable<any> {
    const api = `${environment.searchBaseUrl}/voice-room?term=${term}`
    return this.http.get<any>(api)
  }

  terminateVoiceRoom(voiceRoomId, voiceRoomTitle): Observable<any> {
    let terminatePayload = {
      voiceRoomId: voiceRoomId,
      voiceRoomTerminationReason: 'This room was terminated. If this was done in error please contact support.',
      endedBy: localStorage.getItem('user')
    };
    return this.http.post<any>(environment.backstage.voiceRooms + "admin-terminate", terminatePayload);
  }

  toggleFeaturedState(voiceRoomId, featured): Observable<any> {
    return this.http.post<any>(environment.backstage.voiceRooms + "mark-as-featured?id=" + voiceRoomId + "&featured=" + featured, {});
  }

  toggleSpotlightState(voiceRoomId, spotlight): Observable<any> {
    return this.http.post<any>(`${environment.backstage.voiceRooms}mark-as-spotlight?id=${voiceRoomId}&spotlight=${spotlight}`, {});
  }

  showHideFromHomeFeed(voiceRoomId, state): Observable<any> {
    return this.http.post<any>(environment.backstage.voiceRooms + "hide-from-feed?id=" + voiceRoomId + "&state=" + state, {});
  }

  getReportedAbuseDetails(): Observable<any> {
    let api = environment.adminBaseUrl + "voice-room/report-abuse-details?page=0&size=1000"
    return this.http.get<any>(api);
  }

  fetchRecordedLink(voiceRoomId: string): Observable<any> {
    let api = environment.backstage.voiceRooms + "fetch-recorded-link?id=" + voiceRoomId;
    return this.http.get<any>(api);
  }

  deleteRecordedVoiceRoom(voiceRoomId: string): Observable<any> {
    let api = environment.backstage.voiceRooms + "remove?id=" + voiceRoomId;
    return this.http.post<any>(api, {});
  }

  deleteEvent(eventId) {
    return firebaseFirestoreDb.doc(`${this.collectionName}/${eventId}`).delete();
  }

  fetchEvents = async () => {
    let events = [];
    return firebaseFirestoreDb
      .collection(this.collectionName)
      .orderBy('eventDate', 'asc')
      .get()
      .then((res) => {
        if (!res.empty) {
          events = res.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            return data;
          });
          return events;
        }
        return [];
      });
  }

  createBakstageEvent = async (data: any) => {
    if (data) {
      data.createdOn = new Date();
      return firebaseFirestoreDb.collection(this.collectionName)
        .add(data).then((data) => {
          return !!data.id;
        });
    }
    return Promise.reject('Invalid data!');
  }

  updateBakstageEvent = async (data: any, docId: string) => {
    if (data) {
      data.updatedOn = new Date();
      return firebaseFirestoreDb
        .doc(`${this.collectionName}/${docId}`)
        .update(data)
        .then(() => {
          return true;
        });
    }
    return Promise.reject('Invalid data!');
  }

  fetchAllBackstageList(): Observable<any> {
    let api = environment.backstage.admin + "all";
    return this.http.get<any>(api);
  }

  fetchActiveVoiceRoomsCount(refresh: boolean): Observable<any> {
    let api = environment.backstage.admin + "active-voice-room/all?fetchDb=" + refresh;
    return this.http.get<any>(api);
  }

  fetchActiveBakstageCount(refresh: boolean): Observable<any> {
    let api = environment.backstage.admin + "active-backstage-count?fetchDb=" + refresh;
    return this.http.get<any>(api);
  }

  fetchUserCountPerApp(refresh: boolean): Observable<any> {
    let api = environment.backstage.admin + "user-app-type?fetchDb=" + refresh;
    return this.http.get<any>(api)
  }

  fetchRecordedVoiceRoomCount(refresh: boolean): Observable<any> {
    let api = environment.backstage.admin + "recorded-voice-room-count?fetchDb=" + refresh;
    return this.http.get<any>(api)
  }

  fetchPopularVoiceRoomsInRange(startDate: any, endDate: any, fetchActiveOnly: boolean): Observable<any> {
    let api = environment.backstage.admin + 'voice-rooms?page=0&pageSize=60';
    let body = {
      "roomType": fetchActiveOnly ? "active" : "inactive",
      "fromDate": startDate,
      "toDate": endDate
    }
    return this.http.post<any>(api, body);
  }

  fetchAllPopularActiveVoiceRoomList(): Observable<any> {
    let api = environment.backstage.admin + 'popular-active-voice-rooms?page=0';
    return this.http.get<any>(api)
  }

  fetchAverageVoiceRoomTimeInRange(startDate: any, endDate: any): Observable<any> {
    let api = environment.backstage.admin + 'avg-voice-room-session-time?page=0';
    let body = {
      "fromDate": startDate,
      "toDate": endDate
    }
    return this.http.post<any>(api, body);
  }

  fetchRecordVoiceRoomCountInRange(startDate: any, endDate: any): Observable<any> {
    let api = environment.backstage.admin + 'recorded-voice-room-count-in-time';
    let body = {
      "fromDate": startDate,
      "toDate": endDate
    }
    return this.http.post<any>(api, body);
  }

  fetchRecordVoiceRoomMinutesInRange(startDate: any, endDate: any): Observable<any> {
    let api = environment.backstage.admin + 'recorded-voice-room-session-time';
    let body = {
      "fromDate": startDate,
      "toDate": endDate
    }
    return this.http.post<any>(api, body);
  }

  fetchDeviceTypeCount(refresh: boolean): Observable<any> {
    let api = environment.backstage.admin + "user-device-type?fetchDb=" + refresh;
    return this.http.get<any>(api)
  }

  searchBakstage(term: string): Observable<any> {
    let api = environment.mandaloreBaseUrl + `backstage/search?term=${term}`;
    return this.http.get<any>(api)
  }

  uploadPodcast(formData: any): Promise<any> {
    const api = environment.mandaloreBaseUrl + `podcast/upload`;
    const token = this.cookieService.get(USER_SESSION.tokenKey);
    const headers = {
      Authorization: token
    }

    return fetch(api, {
      method: 'POST',
      body: formData,
      headers
    }).then((res) => res.json());
  }

  uploadImage(formData: any): Promise<any> {
    const api = environment.mandaloreBaseUrl + `utils/image`;
    const token = this.cookieService.get(USER_SESSION.tokenKey);
    const headers = {
      Authorization: token
    }

    return fetch(api, {
      method: 'POST',
      body: formData,
      headers
    }).then((res) => res.json());
  }

  generateYoutubeVideo(body: any): Observable<any> {
    const api = environment.adminServiceBaseUrl + `/utils/generate-youtube-mp3`;
    return this.http.post<any>(api, body);
  }

  createPodcast(request: any): Observable<any> {
    const api = environment.mandaloreBaseUrl + `podcast/create-new`;
    return this.http.post<any>(api, request);
  }

}
