import dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { USER_SESSION } from 'src/app/config/global-constants';
import {LoginSignupService} from 'src/app/Services/login-signup.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private cookieService: CookieService,
    public router: Router,
    private loginSignupService: LoginSignupService
  ) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = this.cookieService.get(USER_SESSION.tokenKey);
    if (token) {
      const scope = state.url.replace(/\//g, '');
      return this.loginSignupService.hasPermission(scope).then((hasPermission) => {
        if (!hasPermission) {
          this.router.navigate(['/login']);
        }
        return hasPermission;
      });
    } else {
      return this.loginSignupService.fetchNewIdToken().then((newIdToken: string) => {
        if (newIdToken) {
          this.cookieService.put(USER_SESSION.tokenKey, newIdToken, {
            expires: dayjs().add(60, 'minutes').toDate(),
          });
          return Promise.resolve(true);
        } else {
          this.router.navigate(['/login']);
          return Promise.resolve(false);
        }
      });
    }
  }
}