import { CookieService } from 'ngx-cookie';
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { ServiceSnackbarService } from "../Services/service-snackbar.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { USER_SESSION } from 'src/app/config/global-constants';

@Injectable()
export class AuthHeader implements HttpInterceptor {
  constructor(
    private snackBar: ServiceSnackbarService,
    public router: Router,
    private cookieService: CookieService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.callRequest(req, next);
  }

  callRequest(req, next): Observable<HttpEvent<any>> {
    if (req.url === "https://dev-search.flyxit.com/search/upload") {
      const authReq = req.clone({
        setHeaders: {
          Authorization: this.cookieService.get(USER_SESSION.tokenKey),
        },
      });
      return next.handle(authReq);
    } else {
      const authReq = req.clone({
        setHeaders: {
          Authorization: this.cookieService.get(USER_SESSION.tokenKey),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return next.handle(authReq);
    }
  }
}
