import {animate, state, style, transition, trigger} from '@angular/animations';
import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ServiceSnackbarService} from 'src/app/Services/service-snackbar.service';
import {UsersService} from '../../Services/users.service';
import {BackstageDashboardService} from '../../Services/backstage-dashboard.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import * as uuid from 'uuid';
import {environment} from '../../../environments/environment';
import {ThemePalette} from '@angular/material/core';
import {NgxUiLoaderService} from 'ngx-ui-loader';

interface Person {
  id: string,
  firstName: string,
  lastName: string,
  profilePicUrl: string,
  active: boolean,
  verified: boolean,
  crew: boolean,
  premium: boolean,
  speaking: boolean,
  muted: boolean,
  published: boolean,
  speakerNotificationSent: boolean,
  callInProgress: boolean,
  acceptedInvite: any
}

@Component({
  selector: 'app-bakstage-podcast-utility',
  templateUrl: './bakstage-podcast-utility.component.html',
  styleUrls: ['./bakstage-podcast-utility.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BakstagePodcastUtilityComponent implements OnInit {
  endDate: Date;
  startDate: Date;
  minDate: '2017-01-01';
  maxDate: Date;
  searchText: string;
  bakstageSearchText: string;
  isLoading = false;
  podcast: any = {};
  usersArray: any[] = [];
  bakstageList: any[] = [];
  selectedBakstage: any;
  userTypes: string[] = ['Creator', 'Moderators', 'Speakers']
  selectedUserType: string;
  podcastTypes: string[] = ['MP3', 'Youtube']
  selectedPodcastType: string;
  creator: any;
  moderators = new Set();
  speakers = new Set();
  fileLocation: string;
  file: File | null = null;
  form: FormGroup;
  youtubeLink: any;
  youtubeLinkUrl: any;
  private = false;
  featured = false;
  color: ThemePalette = 'accent';
  runtimeInMins = 12;
  podcastUrl: string;
  loadingText = '';
  youtubeVideoConverted = false;

  constructor(
    private usersService: UsersService,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe,
    public backstageDashboardService: BackstageDashboardService,
    public fb: FormBuilder,
    private _sanitizer: DomSanitizer,
    private ngxService: NgxUiLoaderService
  ) {
    this.form = this.fb.group({
      name: [''],
      avatar: [null]
    })
  }

  ngOnInit(): void {
    /*    this.loadingText = 'Youtube podcast generation might take a while. Go get a snack till we convert your video to a podcast.';
        this.ngxService.start();
        setTimeout(()=>{
          this.ngxService.stop();
        }, 1500);*/
  }

  searchUser(term: string) {
    this.usersArray = [];
    if (term && term.length > 2) {
      this.usersArray = [];
      this.usersService.searchUser(term).subscribe(data => {
        this.usersArray = data;
      });
    }
  }

  searchBakstage(term: string) {
    this.bakstageList = [];
    if (term && term.length > 2) {
      this.bakstageList = [];
      this.backstageDashboardService.searchBakstage(term).subscribe(data => {
        this.bakstageList = data;
      });
    } else if (term || term.length === 0) {
      this.selectedBakstage = null;
    }
  }

  selectBakstage(bakstage: any) {
    this.selectedBakstage = bakstage;
    this.bakstageList = [];
  }

  selectUser(user: any) {
    if (this.selectedUserType === 'Creator') {
      this.creator = user;
      this.moderators.add(user);
    } else if (this.selectedUserType === 'Moderators') {
      this.moderators.add(user);
    } else if (this.selectedUserType === 'Speakers') {
      this.speakers.add(user);
    }
    this.searchText = null;
    this.usersArray = [];
  }

  removeCreator() {
    this.creator = null;
  }

  removeModerator(moderator: any) {
    this.moderators.delete(moderator);
  }

  removeSpeaker(speaker: any) {
    this.speakers.delete(speaker);
  }

  uploadPodcast(event) {
    this.loadingText = 'Uploading MP3';
    this.ngxService.start();
    const fileToUpload = event.target.files[0];
    const data = new FormData();
    data.append('file', fileToUpload);
    this.backstageDashboardService.uploadPodcast(data).then(res => {
      console.log(res);
      const keys = Object.keys(res);
      this.fileLocation = keys[0];
      this.runtimeInMins = res[this.fileLocation];
      console.log(this.fileLocation);
      this.ngxService.stop();
    }, (err) => {
      this.ngxService.stop();
      this.snackBar.open('Error while uploading mp3');
    });
  }

  uploadImage(event) {
    this.loadingText = 'Uploading podcast image.';
    this.ngxService.start();
    const fileToUpload = event.target.files[0];
    const data = new FormData();
    data.append('file', fileToUpload);
    this.backstageDashboardService.uploadImage(data).then(res => {
      console.log(res);
      this.podcast.imageAssetUrl = res.imageAssetUrl;
      this.ngxService.stop();
    }, (err) => {
      this.ngxService.stop();
      this.snackBar.open('Error while uploading podcast image.');
    });
  }

  generateYoutubeVideo() {
    this.loadingText = 'Youtube podcast generation might take a while. Go get a snack till we convert your video to a podcast.';
    this.ngxService.start();
    const payload = {
      youtubeUrl: this.youtubeLinkUrl,
      bucketName: environment.backstage.assetBucket,
      key: uuid.v4()

    }
    this.backstageDashboardService.generateYoutubeVideo(payload).subscribe(res => {
      console.log(res);
      this.fileLocation = res.url;
      this.runtimeInMins = res.timeInMins;
      this.ngxService.stop();
      this.youtubeVideoConverted = true;
    }, (err) => {
      this.ngxService.stop();
      this.snackBar.open('Error while creating mp3 from youtube link.');
    });
  }

  detectNewYoutubeVideo(youtubeUrl: string) {
    this.youtubeLink = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + youtubeUrl.split('?v=')[1]);
  }

  createPodcast() {
    this.loadingText = 'Creating Podcast';
    this.ngxService.start();
    const podcastPayload = {
      backstage: {
        id: this.selectedBakstage.id,
        title: this.selectedBakstage.title,
        shortTitle: this.selectedBakstage.shortTitle,
        imageAssetUrl: this.selectedBakstage.imageAssetUrl,
        entityType: this.selectedBakstage.entityType,
        entityId: this.selectedBakstage.entityId,
        featured: this.selectedBakstage.featured
      },
      title: this.podcast.title,
      description: this.podcast.description,
      recordingLocation: this.fileLocation,
      runtimeInMins: this.runtimeInMins,
      privateRoom: this.private,
      featured: this.featured,
      fileType: 'mp3',
      createdBy: {
        id: this.creator.id,
        firstName: this.creator.firstName,
        lastName: this.creator.lastName,
        profilePicUrl: this.creator.profileImageUrl,
        active: true,
        verified: this.creator.verified,
        crew: this.creator.crewId === null,
        premium: false,
        speaking: false,
        muted: false,
        published: true,
        speakerNotificationSent: true,
        callInProgress: false,
        acceptedInvite: true
      },
      moderators: this.convertToPersonObject(this.moderators),
      speakers: this.convertToPersonObject(this.speakers),
      imageAssetUrl: this.podcast.imageAssetUrl
    }

    console.log(podcastPayload)

    this.backstageDashboardService.createPodcast(podcastPayload).subscribe(res => {
      console.log(res);
      this.podcastUrl = `${environment.backstage.weblink}?bkstgRoomType=podcast&bkstgRoomId=${res.id}`;
      this.snackBar.open('Successfully Created Podcast with id: ' + res.id + '. Check the Bakstage app!');
      this.podcast = {};
      this.selectedBakstage = null;
      this.creator = null;
      this.moderators = new Set<any>();
      this.speakers = new Set<any>();
      this.private = false;
      this.featured = false;
      this.ngxService.stop();
      this.loadingText = '';
    }, (err) => {
      this.ngxService.stop();
      this.snackBar.open('Error while creating a podcast. Please try again later.');
      this.loadingText = '';
    });
  }

  convertToPersonObject(input: Set<any>): Person[] {
    const arr = [];
    input.forEach(x => {
      arr.push({
        id: x.id,
        firstName: x.firstName,
        lastName: x.lastName,
        profilePicUrl: x.profileImageUrl,
        active: true,
        verified: x.verified,
        crew: x.crewId === null,
        premium: false,
        speaking: false,
        muted: false,
        published: true,
        speakerNotificationSent: true,
        callInProgress: false,
        acceptedInvite: true
      })
    })
    return arr;
  }

  setFeaturedValue(event) {
    this.featured = event.checked;
  }

  setPrivateValue(event) {
    this.private = event.checked;
  }

}
