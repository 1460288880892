import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-award-categories',
  templateUrl: './award-categories.component.html',
  styleUrls: ['./award-categories.component.css']
})
export class AwardCategoriesComponent implements OnInit {
  selectedContent = true
  constructor() { }

  ngOnInit(): void {
  }

  

}
