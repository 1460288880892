export const globalConstants = {
    videoTypes: ["standardWeb", "tvOs", "android"],
    offerTypes: ["Subscription", "Buy", "Rent"],
    deliveryMethods: ["OnDemand"],
    currencies: ["INR", "USD"],
    qualities: ["HD", "SD"]
};

export const USER_SESSION = {
    tokenKey: 'token',
    userKey: 'user',
    scopesKey: 'scopes'
};