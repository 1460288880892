import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-participants',
  templateUrl: './filter-participants.component.html',
  styleUrls: ['./filter-participants.component.css']
})
export class FilterParticipantsComponent implements OnInit {
  @Input() participants: any[];
  @Input('participantType') type : string;
  filteredData: any[] = [];
  isActive:boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    this.filteredData = this.participants;
    this.isActive = this.checkActiveParticipant();
  }
  checkActiveParticipant():boolean {
    for (var i=0;i<this.participants.length;i++){
      if(this.participants[i]?.active==true){
        return true;
      }
    }
    return false;
  }

  applyFilter(value:string){
    if(value && value!=""){
      const formattedSearch = value.toLowerCase();
      this.filteredData = this.participants.filter(x => x.firstName.toLowerCase().includes(formattedSearch));
    }
    else{
      this.filteredData = this.participants;
    }
  }
}
