import {animate, state, style, transition, trigger} from '@angular/animations';
import {DatePipe} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {ServiceSnackbarService} from 'src/app/Services/service-snackbar.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UsersService} from "../../../Services/users.service";

export interface DialogData {
  name: string;
  points: number;
  pointsRedeemed: number;
}

@Component({
  selector: 'app-points-redemption',
  templateUrl: './points-redemption.component.html',
  styleUrls: ['./points-redemption.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PointsRedemptionComponent implements OnInit {
  redeemRequests: any[]

  constructor(
    private service: UsersService,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.findAllPointsRedeemRequests();
  }

  openDialog(request: any): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {name: request.name, points: request.points?.totalPoints, id: request.userId}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result.pointsRedeemed) {
        this.service.adminPayPoints(result.pointsRedeemed, result.id).subscribe((res) => {
          this.snackBar.open('Successfully marked user payout');
          this.findAllPointsRedeemRequests();
        }), (err) => {
          this.snackBar.open('Failed registering user payout');
        }
      } else {
        this.snackBar.open('No action taken');
      }
    });
  }

  findAllPointsRedeemRequests() {
    this.service.findAllPointsRedeemRequests().subscribe((res) => {
      this.redeemRequests = res.content;
      // this.activeVoiceRooms.paginator = this.paginator.toArray()[0];
    }), (err) => {
      this.snackBar.open('Error fetching redeem requests');
    }
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'payout.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogOverviewExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
