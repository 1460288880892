import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/Services/shared.service';

@Component({
  selector: 'app-crew',
  templateUrl: './crew.component.html',
  styleUrls: ['./crew.component.css']
})
export class CrewComponent implements OnInit {

  constructor(public sharedService: SharedService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.sharedService.showNavBar();
  }

}
