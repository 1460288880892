import dayjs from 'dayjs';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BackstageDashboardService } from 'src/app/Services/backstage-dashboard.service';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';

@Component({
  selector: 'app-bakstage-metrics',
  templateUrl: './bakstage-metrics.component.html',
  styleUrls: ['./bakstage-metrics.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BakstageMetricsComponent implements OnInit {
  activeBakstageCount: any;
  userCountPerApp: any;
  voiceRoomEndDate: Date;
  voiceRoomStartDate: Date;
  voiceRoomEndDate1: Date;
  voiceRoomStartDate1: Date;
  voiceRoomEndDate2: Date;
  voiceRoomStartDate2: Date;
  minDate: "2017-01-01";
  maxDate: Date;
  lastUpdatedAt: string;
  refreshLastUpdatedAt: boolean= false;
  averageVoiceRoomTime: any;
  recordedVoiceRoomCount: any;
  activeVoiceRoomsCount: any;
  activeRecordedVoiceRoomsCount:any;
  userDeviceCount:any;
  recordedVoiceRoomMinutes:any;
  providerchartOptions = {};

  constructor(
    private service: BackstageDashboardService,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.maxDate = new Date();
    // Set date range for 30 days
    this.voiceRoomStartDate = dayjs().subtract(30, 'day').toDate();
    this.voiceRoomEndDate = new Date();
    this.voiceRoomStartDate1 = dayjs().subtract(30, 'day').toDate();
    this.voiceRoomEndDate1 = new Date();
    this.voiceRoomStartDate2 = dayjs().subtract(30, 'day').toDate();
    this.voiceRoomEndDate2 = new Date();
  }

  ngAfterViewInit() {
    this.fetchAllMetrics();
  }

  fetchAllMetrics() {
    this.fetchActiveVoiceRoomsCount();
    this.fetchActiveBakstageCount();
    this.fetchUserCountPerApp();
    this.fetchAverageVoiceRoomTimeInRange();
    this.fetchRecordedVoiceRoomsCount();
    this.fetchRecordedVoiceRoomCountInRange();
    this.fetchRecordVoiceRoomMinutesInRange();
    this.fetchDeviceTypeCount();
  }

  fetchActiveVoiceRoomsCount() {
    this.service.fetchActiveVoiceRoomsCount(this.refreshLastUpdatedAt).subscribe((data) => {
      // this.lastUpdatedAt = dayjs.utc(data.updatedAt).local().format('MMM D, YYYY hh:mm A');
      this.activeVoiceRoomsCount = data;
    });
  }

  fetchActiveBakstageCount() {
    this.service.fetchActiveBakstageCount(this.refreshLastUpdatedAt).subscribe((data) => {
      // this.lastUpdatedAt = dayjs.utc(data.updatedAt).local().format('MMM D, YYYY hh:mm A');
      this.activeBakstageCount = data;
    });
  }

  fetchUserCountPerApp() {
    this.service.fetchUserCountPerApp(this.refreshLastUpdatedAt).subscribe((data) => {
      this.userCountPerApp = data;
      this.prepareUsersByProviderChart(this.userCountPerApp)
    });
  }

  fetchAverageVoiceRoomTimeInRange() {
    let start: string = this.datePipe.transform(this.voiceRoomStartDate, 'yyyy-MM-dd');
    let end: string = this.datePipe.transform(this.voiceRoomEndDate, 'yyyy-MM-dd');
    this.service.fetchAverageVoiceRoomTimeInRange(start, end).subscribe((data)=>{
      this.averageVoiceRoomTime = data;
   });
  }

  fetchRecordedVoiceRoomCountInRange() {
    let start: string = this.datePipe.transform(this.voiceRoomStartDate1, 'yyyy-MM-dd');
    let end: string = this.datePipe.transform(this.voiceRoomEndDate1, 'yyyy-MM-dd');
    this.service.fetchRecordVoiceRoomCountInRange(start, end).subscribe((data)=>{
      this.recordedVoiceRoomCount = data;
   });
  }


  fetchRecordedVoiceRoomsCount() {
    this.service.fetchRecordedVoiceRoomCount(this.refreshLastUpdatedAt).subscribe((data) => {
     // this.lastUpdatedAt = dayjs.utc(data.updatedAt).local().format('MMM D, YYYY hh:mm A');
      this.activeRecordedVoiceRoomsCount = data;
    });
  }
  fetchRecordVoiceRoomMinutesInRange() {
    let start: string = this.datePipe.transform(this.voiceRoomStartDate2, 'yyyy-MM-dd');
    let end: string = this.datePipe.transform(this.voiceRoomEndDate2, 'yyyy-MM-dd');
    this.service.fetchRecordVoiceRoomMinutesInRange(start, end).subscribe((data)=>{
      this.recordedVoiceRoomMinutes = data;
   });
  }

  fetchDeviceTypeCount(){
    this.service.fetchDeviceTypeCount(this.refreshLastUpdatedAt).subscribe((data) => {
      this.lastUpdatedAt = dayjs.utc(data.updatedAt).local().format('MMM D, YYYY hh:mm A');
      this.userDeviceCount = data;
    });
  }

  prepareUsersByProviderChart(pieData?: any) {
    this.providerchartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: "Primary app of users"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'primaryApp',
        colorByPoint: true,
        data: pieData
      }]
    };
  }
}
