import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ServiceSnackbarService } from './service-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AwardsCategoriesService {

  http: HttpClient;

  constructor(http: HttpClient,
    public snackBar: ServiceSnackbarService) {
    this.http = http;
  }
  listAllAwards() {
    return this.http.get<any>(
      environment.thespis.awardUrl + "/all"
    );
  }

  listAwardCategories(awardName) {
    return this.http.get<any>(
      environment.thespis.awardCategoryUrl + "?awardName=" + awardName
    );
  }

  updateNomination(nomination) {
    let api = environment.thespis.awardCategoryUrl;
    /* local api
    let api = environment.adminBaseUrl + "/update-content/"
  */
    this.http.post<any>(api, nomination).subscribe((data) => {
      this.snackBar.open("Successfully made updates to - "+ data.name);
    }, (err) => {
      this.snackBar.open("Error: " + err.status + "\n There was an error while saving the data. Please make the changes again");
    });
  }

  uploadImage(file, path) {

    let api = environment.thespis.searchBaseUrl + "/upload";
    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', path)
    return this.http.post<any>(api, formData);
  }

  listTotalVotesCount() {
    return this.http.get<any>(
      environment.thespis.awardMetrics + "/total-votes"
    );
  }

  listVotesByCategory() {
    return this.http.get<any>(
      environment.thespis.awardMetrics + "/vote-by-category"
    );
  }

  listVotesByEntries() {
    return this.http.get<any>(
      environment.thespis.awardMetrics + "/vote-by-category-item"
    );
  }
}
