import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class GlobalObjService {

  get windowRef() {
    return window
  }
  constructor(
  ) { }

}
