import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
  selector: "backstage-confirmation-dialog",
  templateUrl: "backstage-confirmation-dialog.html",
})
export class BackstageConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<BackstageConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
