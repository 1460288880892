import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Component, NgZone, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTabChangeEvent} from '@angular/material/tabs/tab-group';
import {BackstageDashboardService} from '../../Services/backstage-dashboard.service';
import {SharedService} from '../../Services/shared.service';
import {ServiceSnackbarService} from '../../Services/service-snackbar.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {BackstageConfirmationDialog} from './backstage-confirmation-dialog';

@Component({
  selector: 'app-backstage-dashboard',
  templateUrl: './backstage.component.html',
  styleUrls: ['./backstage.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BackstageComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  selectedTabKey: string = 'activeRooms';
  allBackstageList: any;
  columnDef: string[];
  recordedColDef: string[];
  abuseColumnDef: string[];
  activeVoiceRooms: any;
  recordedVoiceRooms: any;
  selectedVoiceroom: any;
  selectedRecordedVoiceroom: any;
  reportAbuseDetails: any;
  selectedAbuseReport: any;
  selectedRecordedLinkUrl: string;
  bakstagecolumnDef: string[];

  defaultPageSizeOptions: number[] = [50, 100, 150, 200];
  configRecordedVoicerooms: any = {
    defaultPageSize: 50,
    pageSizeOptions: [50, 100, 150, 200],
    totalRecords: 0,
    pageIndex: 0
  };

  configAllBakstages: any = {
    defaultPageSize: 50,
    pageSizeOptions: [50, 100, 150, 200],
    totalRecords: 0
  };
  totalPodcasts = 0;

  constructor(
    private service: BackstageDashboardService,
    public sharedService: SharedService,
    public snackBar: ServiceSnackbarService,
    public dialog: MatDialog,
    private zone: NgZone
  ) {
  }

  ngOnInit(): void {
    this.sharedService.showNavBar();
    this.bakstagecolumnDef = ['title', 'category', 'createdOn', 'subscribers', 'verified', 'featured'];
    this.columnDef = ['expander', 'voiceroomTitle', 'numListeners', 'numSpeakers', 'numModerators', 'createdBy', 'createdOn', 'isPrivate', 'actions'];
    this.recordedColDef = ['voiceroomTitle', 'speakers', 'numModerators', 'numSpeakers', 'numListeners', 'createdBy', 'createdOn', 'runtime', 'spotlight', 'featured', 'hiddenFromMainFeed', 'delete', 'download'];
    this.abuseColumnDef = ['expander', 'abuseComment', 'voiceroomTitle', 'numListeners', 'numSpeakers', 'numModerators', 'actions'];
  }

  ngAfterViewInit() {
    this.fetchAllActiveVoiceRooms();
  }

  handlePageSizeChangeForPodcasts(tabKey: string, evt: PageEvent) {
    // Do nothing
    // console.log('handlePageSizeChange: ', tabKey, evt);
    // alert(JSON.stringify(evt))
    this.fetchAllRecordedVoiceRooms(evt.pageIndex);
  }

  onTabChanged(evt: MatTabChangeEvent) {
    if (evt.index === 0) {
      // Active Voicerooms
      this.fetchAllActiveVoiceRooms();
    } else if (evt.index === 1) {
      // Recorded Voicerooms
      this.fetchAllRecordedVoiceRooms();
    } else if (evt.index === 2) {
      // Events
      this.selectedTabKey = 'podcastCreation';
    } /*else if (evt.index === 3) {
      // Events
      this.selectedTabKey = 'events';
    }*/ else if (evt.index === 3) {
      // Abuse Reports
      this.fetchAllReportedAbuseDetails();
    } else if (evt.index === 4) {
      // Popular Voicerooms
      this.selectedTabKey = 'popularVoicerooms';
    } else if (evt.index === 5) {
      // All Bakstages
      this.fetchAllBakstageList();
    }
  }

  collapseVoiceRoom(element) {
    if (this.selectedVoiceroom == element) {
      this.selectedVoiceroom = null;
    } else {
      this.selectedVoiceroom = element;
    }
  }

  collapseAbuseRow(element) {
    if (this.selectedAbuseReport == element) {
      this.selectedAbuseReport = null;
    } else {
      this.selectedAbuseReport = element;
    }
  }

  applyFilter(value: string) {
    this.activeVoiceRooms.filter = value.trim().toLocaleLowerCase();
  }

  applyRecordedRoomFilter(value: string) {
    this.recordedVoiceRooms.filter = value.trim().toLocaleLowerCase();
  }

  searchVoiceRoom(value: string) {
    if (value && value.length > 2) {
      this.service.searchVoiceRooms(value).subscribe((res) => {
        this.recordedVoiceRooms = new MatTableDataSource<any>(res.recordedRooms);
        this.recordedVoiceRooms.paginator = this.paginator.toArray()[1];
      }), (err) => {
        this.snackBar.open('Error searching for podcasts')
      }
    }else{
      this.fetchAllRecordedVoiceRooms();
    }
  }

  applyAbuseFilter(value: string) {
    this.reportAbuseDetails.filter = value.trim().toLocaleLowerCase();
  }

  fetchAllBakstageList() {
    this.service.fetchAllBackstageList().subscribe((data) => {
      this.allBackstageList = new MatTableDataSource<any>(data);
      this.allBackstageList.paginator = this.paginator.toArray()[3];
      this.configAllBakstages.totalRecords = data.length;
      if (data.length < this.configAllBakstages.pageSizeOptions[0]) {
        this.configAllBakstages.defaultPageSize = data.length;
        this.configAllBakstages.pageSizeOptions = [data.length];
      } else {
        this.configAllBakstages.defaultPageSize = 50;
        this.configAllBakstages.pageSizeOptions = this.defaultPageSizeOptions;
      }
    });
  }

  fetchAllActiveVoiceRooms() {
    this.service.fetchActiveVoiceRooms().subscribe((res) => {
      this.activeVoiceRooms = new MatTableDataSource<any>(res.content);
      this.activeVoiceRooms.paginator = this.paginator.toArray()[0];
    }), (err) => {
      this.snackBar.open("Error displaying active voice rooms")
    }
  }

  fetchAllRecordedVoiceRooms(page?: number) {
    page = !page ? 0 : page;
    this.totalPodcasts = 0;
    this.configRecordedVoicerooms.pageIndex = 0;
    this.service.fetchRecordedVoiceRooms(page).subscribe((res) => {
      this.recordedVoiceRooms = new MatTableDataSource<any>(res.content);
      this.recordedVoiceRooms.paginator = this.paginator.toArray()[1];
      this.recordedVoiceRooms.totalRecords = res.totalElements;
      this.totalPodcasts = res.totalElements;
      this.configRecordedVoicerooms.pageIndex = page;
    });
  }

  fetchAllReportedAbuseDetails() {
    this.service.getReportedAbuseDetails().subscribe((res) => {
      this.reportAbuseDetails = new MatTableDataSource<any>(res.content);
      this.reportAbuseDetails.paginator = this.paginator.toArray()[2];
    }), (err) => {
      this.snackBar.open("Error displaying report abuses");
    }
  }

  downloadRecording(voiceRoomId) {
    this.service.fetchRecordedLink(voiceRoomId).subscribe((res) => {
      this.selectedRecordedLinkUrl = res.url;
      window.open(this.selectedRecordedLinkUrl);
    }), (err) => {
      this.snackBar.open("Error downloading file");
    }
  }

  deleteRecordedVoiceRoom(voiceRoomId, voiceRoomTitle) {
    const dialogRef = this.dialog.open(BackstageConfirmationDialog, {
      width: '500px',
      data: {
        title: 'Delete Podcast',
        buttonText: 'Delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteRecordedVoiceRoom(voiceRoomId).subscribe((res) => {
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' has been deleted.");
          this.fetchAllRecordedVoiceRooms();
        }), (err) => {
          this.snackBar.open("Error deleting podcast");
        }
      } else {
        this.snackBar.open("The Voiceroom '" + voiceRoomTitle + "' has not been deleted.")
      }
    });
  }

  terminateRoom(voiceRoomId, voiceRoomTitle) {
    const dialogRef = this.dialog.open(BackstageConfirmationDialog, {
      width: '500px',
      data: {
        title: 'Terminate voice-room',
        buttonText: 'Terminate'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.terminateVoiceRoom(voiceRoomId, voiceRoomTitle).subscribe((res) => {
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' This room was terminated. If this was done in error please contact support")
          this.fetchAllRecordedVoiceRooms();
        }, (err) => {
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' did not get deleted. Try again")
        });
      } else {
        this.snackBar.open("The Voiceroom '" + voiceRoomTitle + "' is still open.")
      }
    });
  }

  toggleFeaturedState(voiceRoomId, featured, voiceRoomTitle) {
    const dialogRef = this.dialog.open(BackstageConfirmationDialog, {
      width: '500px',
      data: {
        title: 'Toggle Featured State',
        buttonText: featured ? 'Mark as non-featured' : 'Mark as featured'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.toggleFeaturedState(voiceRoomId, !featured).subscribe((res) => {
          const text = featured ? 'non-featured' : 'featured';
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' has been marked as " + text + ".")
          this.fetchAllRecordedVoiceRooms();
        }, (err) => {
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' could not be marked as featured. Try again")
        });
      } else {
        this.snackBar.open("No action was taken on '" + voiceRoomTitle + "'.")
      }
    });
  }

  toggleSpotlightState(voiceRoomId, spotlight, voiceRoomTitle) {
    const dialogRef = this.dialog.open(BackstageConfirmationDialog, {
      width: '500px',
      data: {
        title: 'Toggle Spotlight State',
        buttonText: spotlight ? 'Set as non-spotlight' : 'Set as spotlight'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const text = spotlight ? 'non-spotlight' : 'spotlight';
        this.service.toggleSpotlightState(voiceRoomId, !spotlight).subscribe((res) => {
          this.snackBar.open(`The voice room '${voiceRoomTitle}' has been marked as ${text}.`)
          this.fetchAllRecordedVoiceRooms();
        }, (err) => {
          this.snackBar.open(`The voice room '${voiceRoomTitle}' could not be marked as ${text}. Try again`)
        });
      } else {
        this.snackBar.open(`No action was taken on '${voiceRoomTitle}'.`)
      }
    });
  }

  showHideRecordedRoomFromHomeFeed(voiceRoomId, podcastState, voiceRoomTitle) {
    const dialogRef = this.dialog.open(BackstageConfirmationDialog, {
      width: '500px',
      data: {
        title: 'Show/Hide Podcast from Home Feed',
        buttonText: podcastState ? 'Show' : 'Hide'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.showHideFromHomeFeed(voiceRoomId, !podcastState).subscribe((res) => {
          const text = podcastState ? 'show' : 'hidden';
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' has been set to " + text + ".")
          this.fetchAllRecordedVoiceRooms();
        }, (err) => {
          this.snackBar.open("The voice room '" + voiceRoomTitle + "' could not be marked as featured. Try again")
        });
      } else {
        this.snackBar.open("No action was taken on '" + voiceRoomTitle + "'.")
      }
    });
  }
}
