import { ServiceSnackbarService } from './service-snackbar.service';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SearchItem } from "../models/SearchItem";
import { Observable } from "rxjs";
import { MovieDetails } from '../models/MovieDetails';
import { environment } from 'src/environments/environment';
// import { AvailableOn } from '../models/AvailableOn';

@Injectable({
  providedIn: "root",
})
export class AvailableOnService {

  http: HttpClient;

  constructor(http: HttpClient,
    public snackBar: ServiceSnackbarService) {
    this.http = http;
  }

  fetchavailableOnData(contentId: string, countryId: string): Observable<any> {
    /**
     * Example URL: http://localhost:8080/admin/available-on?contentId=5d3ef2ddc3aec1018c8d3dbf&countryCode=US
     */
    /* contentId = "5d3ef2ddc3aec1018c8d3dbf";
    countryId = "US" */
    return this.http.get<any>(
      environment.adminBaseUrl +
      "available-on?contentId=" +
      contentId +
      "&countryCode=" +
      countryId
    );
  }

  fetchContentProviderList(countryId: string) {
    return this.http.get<any>(
      environment.adminBaseUrl + "content-providers?countryId=" + countryId
    );
  }

  fetchCountryList() {
    return this.http.get<any>(environment.adminBaseUrl + "countries");
  }

  editAvailableOn(availableOn): Observable<any> {
    return this.http.post<any>(environment.adminBaseUrl + "available-on/id", availableOn);
    // return this.http.post<any>("http://localhost:8080/admin/available-on/id", availableOn);
  }
  saveAvailableOn(availableOn): Observable<any>{
    return this.http.post<any>(environment.adminBaseUrl + "available-on", availableOn);
  }

  searchMoviesSeries(titleName: string): Observable<SearchItem[]> {
    let api = environment.searchBaseUrl + "?title=" + titleName;
    return this.http.get<SearchItem[]>(api);
  }
  
  searchCast(crewName): Observable<any[]> {
    let api = environment.searchBaseUrl + "/crew?name=" + crewName;
    return this.http.get<any[]>(api);
  }

  searchContentData(contentId: string): Observable<MovieDetails> {
    let api = environment.searchBaseUrl + "/content?id=" + contentId;
    return this.http.get<MovieDetails>(api);
  }
  

  availableById(contentId: string, contentProviderTitle: string, countryCode: string): Observable<any> {
    return this.http.get<any>(
      environment.adminBaseUrl +
      "available-on/id?contentId=" +
      contentId +
      "&contentProviderTitle=" +
      contentProviderTitle +
      "&countryCode=" +
      countryCode
    );
  }

  recentMoviesSeries() {
    let api = environment.searchBaseUrl + "/templates";
    let body = {
      "id": "5e7fca01aba849043a891690",
      "templateType": "search",
      "contentType": "search",
      "searchText": "Upcoming Movies",
      "name": "Upcoming Movies"
    }
    return this.http.post<any>(api, body);
  }

  fetchGenresList(): Observable<any> {
    let url = environment.yavinBaseUrl + 'utils/genre-accounts'
    return this.http.get<any>(url);
  }

  updateContent(availableOn: MovieDetails) {
    let api = environment.adminBaseUrl + "update-content";
    this.http.put<any>(api, availableOn).subscribe((data) => {
      this.snackBar.open("Data has been saved successfully.");
    }, (err) => {
      this.snackBar.open("Error: " + err.status + "\n There was an error while saving the data. Please make the changes again");
    });
  }
}
