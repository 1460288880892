/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const environment = {
  firebase: {
    apiKey: "AIzaSyBa5Pr336q832WWbScRPiIHW3xPhAsIz2E",
    authDomain: "flyx-dev-87846.firebaseapp.com",
    databaseURL: "https://flyx-dev-87846-default-rtdb.firebaseio.com",
    projectId: "flyx-dev-87846",
    storageBucket: "flyx-dev-87846.appspot.com",
    messagingSenderId: "1065822367479",
    appId: "1:1065822367479:web:fabe2c389938fae70eedf2",
    measurementId: "G-043SG89S55"
  },
  production: false,
  searchBaseUrl: "https://dev-flyx-search.flyxit.com/search",
  yavinBaseUrl: "https://dev-flyx-yavin.flyxit.com/",
  adminBaseUrl: "https://dev-admin-apis.flyxit.com/admin/",
  mandaloreBaseUrl: "https://dev-mandalore.flyxit.com/",
  adminServiceBaseUrl: "https://dev-admin-apis.flyxit.com",
  adminBaseMetricsUrl: "https://dev-admin-apis.flyxit.com/users",
  yavinUserUrl: "https://dev-flyx-yavin.flyxit.com/user",
  yavinMetrics: "https://dev-flyx-yavin.flyxit.com/user-metrics",
  thespis: {
    searchBaseUrl: "https://dev-flyx-search.flyxit.com/search",
    awardUrl: "https://prod-thespis.flyxit.com/award",
    awardCategoryUrl: "https://prod-thespis.flyxit.com/award/category",
    awardMetrics: "https://prod-thespis.flyxit.com/award-metrics"
  },
  backstage:{
    voiceRooms: "https://dev-mandalore.flyxit.com/voice-room/",
    uploadImage: "https://dev-admin-apis.flyxit.com/backstage/upload",
    metrics: "https://dev-admin-apis.flyxit.com/backstage/",
    admin: "https://dev-admin-apis.flyxit.com/backstage/",
    assetBucket: 'flyx-backup',
    weblink: 'https://dev-bakstage.flyx.me'
  }
};

