import dayjs from 'dayjs';
import {firebaseAuth} from './config/firebase-init';
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {ServiceSnackbarService} from './Services/service-snackbar.service';
import {USER_SESSION} from 'src/app/config/global-constants';
import {LoginSignupService} from 'src/app/Services/login-signup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Flyx Admin';
  sidebarMenu = [{
    title: 'Metrics',
    routerLink: 'metrics',
    authorizationScope: 'metrics'
  },
    {
      title: 'Bakstage',
      routerLink: 'backstage',
      authorizationScope: 'backstage'
    },
    {
      title: 'Users',
      routerLink: 'users',
      authorizationScope: 'users'
    },
    {
      title: 'Utils',
      routerLink: 'utils',
      authorizationScope: 'utils'
    },{
      title: 'Trending Feeds',
      routerLink: 'trending-feeds',
      authorizationScope: 'trending-feeds'
    }, {
      title: 'Content',
      routerLink: 'content',
      authorizationScope: 'content'
    },
    {
      title: 'Award Categories',
      routerLink: 'award-categories',
      authorizationScope: 'award-categories'
    }];
  idToken: string = null;
  hasPermission: boolean = false;
  userScopes: string[] = [];

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private snackBar: ServiceSnackbarService,
    private loginSignupService: LoginSignupService
  ) {
  }

  ngOnInit(): void {
    console.log(this.sidebarMenu);
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const currentIdToken = this.cookieService.get(USER_SESSION.tokenKey);
    this.idToken = currentIdToken;

    firebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        const newIdToken = await user.getIdToken();
        this.idToken = newIdToken;
        this.cookieService.put(USER_SESSION.tokenKey, newIdToken, {
          expires: dayjs().add(60, 'minutes').toDate(),
        });
        this.loginSignupService.getUserScopes().then((scopes) => {
          this.userScopes = scopes;
        });
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  isAuthorized(url: string): boolean {
    if (this.idToken) {
      return this.userScopes.includes(url);
    }
    return false;
  }
}
