import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MetricsDashboardService } from 'src/app/Services/metrics-dashboard.service';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';
import { MatTableDataSource } from '@angular/material/table';

export interface UserTableElements {
  firstName: string;
  lastName: string;
  username: string;
}

@Component({
  selector: 'app-user-utils-metrics',
  templateUrl: './user-utils-metrics.component.html',
  styleUrls: ['./user-utils-metrics.component.css']
})

export class UserUtilsMetricsComponent implements OnInit {

  @ViewChild("ngAutocompleteApi", { static: true }) ngAutocompleteApi;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['firstname', 'lastname', 'username'];
  // dataSource = new MatTableDataSource();
  cardShow: boolean = false;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  usersReferred: any[];
  isLoading: boolean = false;
  users: any[];
  selectedUser: any;
  // public keyword = ["firstName", "lastName", "username"];
  public keyword = "firstName";
  public historyHeading: string = "Recently selected";
  dataSource = new MatTableDataSource<UserTableElements>([]);
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25];
  pageEvent: PageEvent;
  pageLength = 10;
  //  exampleDatabase: users | null;

  constructor(private service: MetricsDashboardService, public snackBar: ServiceSnackbarService) { }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  async onChangeSearch(keyword: any) {
    if (keyword != null && keyword != "") {
      this.isLoading = true;
      this.service.searchUsers(keyword).subscribe(
        (res) => {
          this.users = res;
          this.isLoading = false;
        },
        (err) => {
          this.snackBar.open("Error: " + err.statusText + "\n There was an error displaying the users. Please Try Again");
          this.isLoading = false;
        }
      );
    }
  }

  selectEvent(item) {
    this.cardShow = true
    if (item) {
      this.selectedUser = item;
      this.populateUsersReferredBy(item.id)
    }
  }

  focusedEventApi(e: any) {
    // Fetch API data on Load
    this.onChangeSearch(null);
  }

  closedEventApi() {
  }

  openApiPanel(e: { stopPropagation: () => void; }): void {
    e.stopPropagation();
    this.ngAutocompleteApi.open();
  }

  closeApiPanel(e: { stopPropagation: () => void; }): void {
    e.stopPropagation();
    this.ngAutocompleteApi.close();
  }

  focusApiPanel(e: { stopPropagation: () => void; }): void {
    e.stopPropagation();
    this.ngAutocompleteApi.focus();
  }

  populateUsersReferredBy(searchId: string,) {
    /* this.service.fetchUsersReferredBy(searchId).subscribe((res) => {
      this.usersReferred = res;
    }); */
  }
}