import { Component, OnInit } from '@angular/core';
import { AwardsCategoriesService } from 'src/app/Services/awards-categories.service';

@Component({
  selector: 'app-award-categories-metrics',
  templateUrl: './award-categories-metrics.component.html',
  styleUrls: ['./award-categories-metrics.component.css']
})
export class AwardCategoriesMetricsComponent implements OnInit {
  totalVotes;
  currentAwardCategory;
  categoryVotes: [] = [];
  selectedCategoryVotes = [];
  totalEntryVotes: any;
  constructor(private awardCategoriesService: AwardsCategoriesService) { }

  ngOnInit(): void {
    this.populateTotalVotes();
    this.populateTotalVotesByCategories();
    this.populateTotalEntryVotes();
  }

  populateTotalVotes() {
    this.awardCategoriesService.listTotalVotesCount().subscribe(data => {
      this.totalVotes = data;
    });
  }

  populateTotalVotesByCategories() {
    this.awardCategoriesService.listVotesByCategory().subscribe(data => {
      this.categoryVotes = data;
    });
  }

  populateTotalEntryVotes() {
    this.awardCategoriesService.listVotesByEntries().subscribe(data => {
      this.totalEntryVotes = data;
    });
  }

  populateEntryCategoryVotes(category) {
    this.currentAwardCategory = category;
    this.selectedCategoryVotes = this.totalEntryVotes.filter(item => item.awardCategoryId === category.awardCategoryId)
    this.selectedCategoryVotes.sort(this.compare);
  }

  compare(a, b) {
    if (a.total < b.total) {
      return 1;
    }
    if (a.total > b.total) {
      return -1;
    }
    return 0;
  }
}
