import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { animate, trigger, state, style, transition } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { BakstageEventsCreateDialog } from './bakstage-events-create.component';
import { BackstageDashboardService } from 'src/app/Services/backstage-dashboard.service';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-bakstage-events',
  templateUrl: './bakstage-events.component.html',
  styleUrls: ['./bakstage-events.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BakstageEventsComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild(MatSort) sort: MatSort;

  eventList: any = [];
  eventSummary: any;
  createdbakstage: Promise<boolean>;
  selectedEventDetails: any;
  columnDef: string[];

  constructor(private service: BackstageDashboardService,
    public dialog: MatDialog,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe,
    private changeDetectorRefs: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.fetchEventsList();
    this.columnDef = ['expander', 'title', 'status', 'eventDate', 'actions'];
  }

  fetchEventsList() {
    this.service.fetchEvents().then((data) => {
      this.eventList = new MatTableDataSource<any>(data);
      this.eventList.paginator = this.paginator.toArray()[0];
      this.eventList.sort = this.sort;
    });
  }

  convertDate(eventDate){
    return new Date(eventDate.seconds*1000)
  }

  toggleEventDetails(element){
    if(this.selectedEventDetails == element){
      this.selectedEventDetails = null;
    } else {
      this.selectedEventDetails = element;
    }
  }

  deleteEvent(eventDetails: any) {
    if (confirm(`Are you sure about deleting this Event? - ${eventDetails.title}`)) {
      this.service.deleteEvent(eventDetails.id).then(() => {
        this.fetchEventsList();
      });
    }
  }

  addNewEventForm(eventDetails?: any){
    const dialogRef = this.dialog.open(BakstageEventsCreateDialog, {
      width: '1000px',
      data: {
        editEventData: eventDetails
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result || result!=false){
          this.fetchEventsList();
          this.changeDetectorRefs.detectChanges();
          this.snackBar.open("Bakstage Event is saved.")
        } else {
          this.snackBar.open("Error saving Bakstage Event. Please try again!");
        };
    });
  }
}
