import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MetricsDashboardService } from 'src/app/Services/metrics-dashboard.service';
import { SharedService } from 'src/app/Services/shared.service';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';

import { UsersByProvidersData } from 'src/app/Mocks/usersByProviders'
import { UsersByCountryData } from 'src/app/Mocks/usersByCountry';



@Component({
  selector: 'app-metrics-dashboard',
  templateUrl: './metrics-dashboard.component.html',
  styleUrls: ['./metrics-dashboard.component.css']
})
export class MetricsDashboardComponent implements OnInit {
  usersSearch;
  usersReferred: any[];
  isLoading: boolean = false;

  // public keyword = ["firstName", "lastName", "username"];
  public keyword = "firstName";
  public historyHeading: string = "Recently selected";
  users;

  constructor(public sharedService: SharedService,

    private router: Router,
    public snackBar: ServiceSnackbarService) {
  }

  ngOnInit() {
    this.sharedService.showNavBar();
  }


}
