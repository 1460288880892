import { ServiceSnackbarService } from './../../Services/service-snackbar.service';
import { Component, OnInit, NgZone, ViewChild, Input, Inject } from '@angular/core';
import { AvailableOnService } from '../../Services/available-on.service';
import { FormBuilder, FormArray } from '@angular/forms';
import { AvailableOn } from '../../models/AvailableOn';
import { Platform } from '../../models/Platform';
import { FormGroup, FormControl } from '@angular/forms'
import { NgxSpinnerService } from 'ngx-spinner';
import { globalConstants } from 'src/app/config/global-constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-add-new-dialog',
    templateUrl: './add-new-dialog.component.html',
    styleUrls: ['./add-new-dialog.component.css']
})

export class AddNewDialogComponent implements OnInit {
    availableOn: AvailableOn = new AvailableOn();
    platform: Platform = new Platform();
    addData: boolean = false;
    videoTypes: string[] = globalConstants.videoTypes;
    offerTypes: string[] = globalConstants.offerTypes;
    deliveryMethods: string[] = globalConstants.deliveryMethods;
    currencies: string[] = globalConstants.currencies;
    qualities: string[] = globalConstants.qualities;
    allPlatforms: Platform[];
    editIndex: number;
    availableOnForm: FormGroup;
    countries: any = [];
    contentProviders = [];
    obj: any;
    availableOnList: any;
    selectedIndex: number;
    selectedcountry: string = "";
    selectedprovider: string = "";
    selectedQuality: string = "";
    selectedCurrency: string = "";
    selectedDeliveryMethod: string = "";
    selectedOfferType: string = "";
    selectedVideoType: string = "";
    editPlatform: Platform;
    contentPlatformHashMap: {
        contentProvider: String,
        platform: Platform[]
    }[] = new Array();
    updateAvailableOnList: AvailableOn[] =[];

    constructor(@Inject(MAT_DIALOG_DATA) private availableForm: any,
        private service: AvailableOnService,
        private fb: FormBuilder,
        private spinner: NgxSpinnerService,
        private snackBar: ServiceSnackbarService,
        public dialogRef: MatDialogRef<AddNewDialogComponent>,
        ) {

        this.availableOn.contentId = availableForm.contentId;
        this.selectedcountry = availableForm.countryCode;
        this.selectedprovider = availableForm.selectedContentProvider
        if (availableForm.selectedPlatformIndex != undefined) {
            this.allPlatforms = availableForm.availableOnList.find(x => x.contentProviderTitle === this.selectedprovider).platform;
            this.editIndex = availableForm.selectedPlatformIndex;
            this.editPlatform = this.allPlatforms[this.editIndex];
        }
        this.populateContentProviders(this.selectedcountry);
    }

    ngOnInit() {
        this.availableForm.selectedPlatformIndex != undefined ? this.addData = false : this.addData = true
        //this.populateCountries();
        this.availableOnForm = this.fb.group({
            addForm: this.fb.array([

            ])
        });
        this.getAddForm();
    }

    getAddForm() {
        const fa = (this.availableOnForm.get('addForm') as FormArray);
        if (!this.addData) {
            return fa.push(
                this.fb.group({
                    contentProvider: this.editPlatform.name,
                    country: this.editPlatform.countryCode,
                    videoType: this.editPlatform.type,
                    offerTypes: this.editPlatform.offerType,
                    deliveryMethod: this.editPlatform.deliveryMethod,
                    currency: this.editPlatform.currency,
                    quality: this.editPlatform.quality,
                    price: this.editPlatform.price,
                    availableOnURL: this.editPlatform.url
                }));
        }
        fa.push(this.fb.group({
            contentProvider: new FormControl('', []),
            country: new FormControl(['']),
            videoType: new FormControl(['']),
            offerTypes: new FormControl(['']),
            deliveryMethod: new FormControl(['']),
            currency: new FormControl(['']),
            quality: new FormControl(['']),
            price: new FormControl(''),
            availableOnURL: new FormControl('')
        }));
        this.selectedprovider = "";
        this.selectedQuality = "";
        this.selectedCurrency = "";
        this.selectedDeliveryMethod = "";
        this.selectedOfferType = "";
        this.selectedVideoType = "";
    }
    
    removeUnit(index) {
        const control = (this.availableOnForm.get('addForm') as FormArray);
        control.removeAt(index);
    }

    populateContentProviders(newCountry) {
        this.contentProviders = [];
        if (newCountry != null || newCountry != "") {
            this.service.fetchContentProviderList(this.selectedcountry).subscribe((res) => {
                this.obj = res;
                let len = this.obj.length;
                for (let i = 0; i < len; i++) {
                    this.contentProviders.push(this.obj[i]);
                }
            }, (err) => {
                    this.snackBar.open("Error: " + err.status + "\n There was an error displaying the Content Providers. Please Try Again");
            });
        }
    }

    changeProvider(newProvider: string, i: number) {
        const control = this.availableOnForm.controls.addForm['controls'][i].controls;
        control.contentProvider.setValue(newProvider);
        this.selectedprovider = newProvider;
    }

    changeQuality(newQuality: string, i) {
        const control = this.availableOnForm.controls.addForm['controls'][i].controls;
        control.quality.setValue(newQuality);
        this.selectedQuality = newQuality;
    }

    changeCurrency(newCurrency: string, i) {
        const control = this.availableOnForm.controls.addForm['controls'][i].controls;
        control.currency.setValue(newCurrency);
        this.selectedCurrency = newCurrency;
    }

    changeDeliveryMethod(newDeliveryMethod: string, i) {
        const control = this.availableOnForm.controls.addForm['controls'][i].controls;
        control.deliveryMethod.setValue(newDeliveryMethod);
        this.selectedDeliveryMethod = newDeliveryMethod;
    }

    changeOfferType(newofferType: string, i) {
        const control = this.availableOnForm.controls.addForm['controls'][i].controls;
        control.offerTypes.setValue(newofferType);
        this.selectedOfferType = newofferType;
    }

    changeVideoType(newvideoType: string, i) {
        const control = this.availableOnForm.controls.addForm['controls'][i].controls;
        control.videoType.setValue(newvideoType);
        this.selectedVideoType = newvideoType;
    }
    onEdit(allForms) {
        this.platform = {
            countryCode: this.selectedcountry,
            currency: allForms.value.currency,
            deliveryMethod: allForms.value.deliveryMethod,
            name: allForms.value.contentProvider,
            offerType: allForms.value.offerTypes,
            price: allForms.value.price,
            quality: allForms.value.quality,
            type: allForms.value.videoType,
            url: allForms.value.availableOnURL
        };
        this.allPlatforms[this.editIndex] = this.platform
        this.availableOn = {
            contentId: this.availableOn.contentId,
            contentProviderTitle: this.editPlatform.name,
            countryCode: this.selectedcountry,
            platform: this.allPlatforms
        };
        this.spinner.show;
        this.service.editAvailableOn(this.availableOn).subscribe(
            (data) => {
              this.dialogRef.close(data);
            },
            (err) => {
              this.snackBar.open("Error: " + err.status + "\n There was an error while saving the data. Please make the changes again");
            }
          );
        this.spinner.hide;
    }

    populatePlatform(newPlatform: Platform, contentProvider: String) {
        let addPlatforms = this.availableForm.availableOnList.find(x => x.contentProviderTitle === contentProvider);
        if (addPlatforms !== undefined) {
            addPlatforms.platform.push(newPlatform)
            this.contentPlatformHashMap.push({
                contentProvider: contentProvider,
                platform: addPlatforms.platform
            });
        }
        else {
            this.contentPlatformHashMap.push({
                contentProvider: contentProvider,
                platform: [newPlatform]
            });
        }
    }

    onSubmit(allForms) {
        if (!this.addData) {
            this.onEdit(allForms[0])
        }
        else {
            allForms.map(form => {
                this.platform = {
                    countryCode: form.value.country,
                    currency: form.value.currency,
                    deliveryMethod: form.value.deliveryMethod,
                    name: form.value.contentProvider,
                    offerType: form.value.offerTypes,
                    price: form.value.price,
                    quality: form.value.quality,
                    type: form.value.videoType,
                    url: form.value.availableOnURL
                };
                this.populatePlatform(this.platform, form.value.contentProvider)
            });

            this.contentPlatformHashMap.forEach((provider) => {
                this.availableOn = {
                    contentId: this.availableOn.contentId,
                    contentProviderTitle: provider.contentProvider,
                    countryCode: this.selectedcountry,
                    platform: provider.platform,
                };
                this.updateAvailableOnList.push(this.availableOn)
            });
            this.service.saveAvailableOn(this.updateAvailableOnList).subscribe( (data) => {
                this.dialogRef.close(data);
            },(error)=>{
                this.snackBar.open("Error: " + error.status + "\n There was an error while saving the data. Please make the changes again");
            });
        }
        
    }


}
