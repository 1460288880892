import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import { FormGroup } from "@angular/forms";
import { BackstageDashboardService } from "src/app/Services/backstage-dashboard.service";
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';

@Component({
  selector: "backstage-events-create-dialog",
  templateUrl: "bakstage-events-create.component.html",
  styleUrls: ["./bakstage-events-create.component.css"],
})
export class BakstageEventsCreateDialog {
  createdbakstage: any;
  fileToUpload: File;
  addNewEventForm: any;
  docDate = new Date();
  isEditingEvent: boolean = false;
  message: string;
  clientTimezone: string;
  isImageUploading: boolean = false;

  constructor(
    private service: BackstageDashboardService,
    public dialogRef: MatDialogRef<BakstageEventsCreateDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public datePipe: DatePipe,
    private snackBar: ServiceSnackbarService,
  ) {}

  ngOnInit(): void {
    this.clientTimezone = dayjs.tz.guess();
    let eventData = {
      title: "",
      description: "",
      eventDate: {},
      bannerImage: "",
      thumbImage: "",
      tags: [new FormControl("")],
      hosts: [
        new FormGroup({
          name: new FormControl(""),
          profilePic: new FormControl(""),
        }),
      ],
      eventStatus: "",
      slug: "",
    };
    if (this.data && this.data.editEventData) {
      this.isEditingEvent = true;
      const {
        title,
        description,
        eventDate,
        eventStatus,
        bannerImage,
        thumbImage,
        slug,
      } = this.data.editEventData;
      eventData = Object.assign(eventData, {
        title,
        description,
        bannerImage,
        eventStatus,
        thumbImage,
        slug
      });
      if (eventDate && eventDate.seconds) {
        let transformedDate = new Date(eventDate.seconds * 1000);
        eventData.eventDate = this.datePipe.transform(
          transformedDate,
          "yyyy-MM-ddThh:mm"
        );
      }
      eventData.tags = this.data.editEventData.tags.map((t) => {
        return new FormControl(t);
      });
      eventData.hosts = this.data.editEventData.hosts.map((h) => {
        return new FormGroup({
          name: new FormControl(h.name),
          profilePic: new FormControl(h.profilePic),
        });
      });
    }
    this.addNewEventForm = this.fb.group({
      title: new FormControl(eventData.title, [Validators.required]),
      description: new FormControl(eventData.description, [
        Validators.required,
      ]),
      hosts: this.fb.array(eventData.hosts),
      tags: this.fb.array(eventData.tags),
      eventDate: new FormControl(eventData.eventDate, [Validators.required]),
      bannerImage: new FormControl(eventData.bannerImage),
      thumbImage: new FormControl(eventData.thumbImage, [Validators.required]),
      eventStatus: new FormControl(eventData.eventStatus, [
        Validators.required,
      ]),
      slug: new FormControl(eventData.slug, [
        Validators.required,
      ]),
    });
  }

  get hosts(): FormArray {
    return this.addNewEventForm.get("hosts") as FormArray;
  }

  get tags(): FormArray {
    return this.addNewEventForm.get("tags") as FormArray;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addHosts() {
    this.hosts.push(
      new FormGroup({
        name: new FormControl(""),
        profilePic: new FormControl(""),
      })
    );
  }

  addTags() {
    this.tags.push(new FormControl(""));
  }

  addNewEvent(): void {
    if (this.addNewEventForm.status === 'VALID') {
      this.addNewEventForm.value.eventDate = new Date(
        this.datePipe.transform(
          this.addNewEventForm.value.eventDate,
          "yyyy-MM-ddTHH:mm:ss.SSSZ"
        )
      );
      if (this.data && this.data.editEventData) {
        this.service
          .updateBakstageEvent(
            this.addNewEventForm.value,
            this.data.editEventData.id
          )
          .then((isSuccess) => {
            this.dialogRef.close(isSuccess);
          });
      } else {
        this.service
          .createBakstageEvent(this.addNewEventForm.value)
          .then((bakstageId) => {
            this.dialogRef.close(bakstageId);
          });
      }
    } else {
      this.message = 'Please enter all the required fields (*) and then Save.';
    }
  }

  async handleFileInput(evt: any) {
    const fileToUpload = evt.target.files[0];

    var data = new FormData();
    data.append("file", fileToUpload);

    this.isImageUploading = true;
    this.service.uploadEventImage(data).then((resData) => {
      this.snackBar.open("Uploaded file..");
      this.addNewEventForm.get('thumbImage').setValue(resData.filePath);
      this.addNewEventForm.get('bannerImage').setValue(resData.filePath);
    }).catch((e) => {
      console.error(e);
      this.snackBar.open("Error: There was an error uploading the file.");
    }).finally(() => {
      this.isImageUploading = false;
    });
  }

  delete() {
    
  }

  generateSlug(evt: any) {
    const slugBeforeChange = this.addNewEventForm.controls['slug'].value;
    // if (!slugBeforeChange) {
      const title = evt.target.value;
      // const re = /[a-zA-Z0-9\s:]+/;
      const slug = `${title.replace(/[\s:]+/g, '-')}-${dayjs().unix()}`;
      this.addNewEventForm.controls['slug'].setValue(slug);
    // }
  }
}
