import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatDialogModule } from "@angular/material/dialog";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { httpInterceptorProvider } from "./HttpInterceptor";
import { NgRedux, NgReduxModule } from "@angular-redux/store";
import { IAppState, rootReducer, Initial_State } from "./store";
import { Ng2TelInputModule } from "ng2-tel-input";
import { NgOtpInputModule } from "ng-otp-input";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { CookieModule } from "ngx-cookie";
import { MaterialModule } from "./material-module";

import { CarouselModule } from "ngx-bootstrap/carousel";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AvailableOnComponent } from "./pages/available-on/available-on.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { AddNewDialogComponent } from "./components/add-new-dialog/add-new-dialog.component";
import { HomeComponent } from './components/home/home.component';
import { CrewComponent } from './components/crew/crew.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { EditDetailsComponent } from './components/edit-details/edit-details.component';
import { TrendingFeedsComponent } from './pages/trending-feeds/trending-feeds.component';
import { MetricsDashboardComponent } from './pages/metrics-dashboard/metrics-dashboard.component';
import { ChartModule } from 'angular-highcharts';
import { AggregatedMetricsComponent } from './components/aggregated-metrics/aggregated-metrics.component';
import { UserUtilsMetricsComponent } from './components/user-utils-metrics/user-utils-metrics.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HighchartsChartModule } from 'highcharts-angular';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { AwardsComponent } from './components/awards/awards.component';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { AuthGuardService } from './Services/auth-guard-service';
import { AwardCategoriesMetricsComponent } from './components/award-categories-metrics/award-categories-metrics.component';
import { AwardCategoriesContentComponent } from './components/award-categories-content/award-categories-content.component';
import { AwardCategoriesComponent } from './pages/award-categories/award-categories.component';
import { BackstageComponent } from './pages/backstage/backstage.component';
import { AuthHeader } from "./HttpInterceptor/auth-header.interceptor";
import { FilterParticipantsComponent } from './components/filter-participants/filter-participants.component';
import { BakstageEventsComponent } from './components/bakstage-events/bakstage-events.component';
import { BakstageEventsCreateDialog } from './components/bakstage-events/bakstage-events-create.component';
import { BakstageMetricsComponent } from './components/bakstage-metrics/bakstage-metrics.component';
import { BakstagePopularVoiceroomsComponent } from './components/bakstage-popular-voicerooms/bakstage-popular-voicerooms.component';
import { BackstageConfirmationDialog } from "./pages/backstage/backstage-confirmation-dialog";
import {UsersComponent} from './components/users/users.component';
import {
  DialogOverviewExampleDialog,
  PointsRedemptionComponent
} from "./components/users/points-redemption/points-redemption.component";
import {UtilsComponent} from "./components/utils/utils.component";
import { EmailComponent } from './components/utils/email/email.component';
import {BakstagePodcastUtilityComponent} from "./components/bakstage-podcast-utility/bakstage-podcast-utility.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";

@NgModule({
  entryComponents: [
    AddNewDialogComponent,
    BakstageEventsCreateDialog,
    BackstageConfirmationDialog,
    DialogOverviewExampleDialog
  ],
  exports: [
    NavbarComponent
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    AvailableOnComponent,
    AddNewDialogComponent,
    HomeComponent,
    CrewComponent,
    NavbarComponent,
    EditDetailsComponent,
    TrendingFeedsComponent,
    MetricsDashboardComponent,
    AggregatedMetricsComponent,
    UserUtilsMetricsComponent,
    CampaignsComponent,
    AwardsComponent,
    OrganizationsComponent,
    ImageUploaderComponent,
    AwardCategoriesMetricsComponent,
    AwardCategoriesContentComponent,
    AwardCategoriesComponent,
    BackstageComponent,
    FilterParticipantsComponent,
    BakstageEventsComponent,
    BakstageEventsCreateDialog,
    BackstageConfirmationDialog,
    BakstageMetricsComponent,
    BakstagePopularVoiceroomsComponent,
    UsersComponent,
    PointsRedemptionComponent,
    DialogOverviewExampleDialog,
    UtilsComponent,
    EmailComponent,
    BakstagePodcastUtilityComponent
  ],
  imports: [
    CookieModule.forRoot(),
    MaterialModule,
    NgbModule,
    MatDialogModule,
    CommonModule,
    NgxSpinnerModule,
    NgReduxModule,
    MatFormFieldModule,
    MatInputModule,
    Ng2TelInputModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgOtpInputModule,
    AutocompleteLibModule,
    CarouselModule,
    SlickCarouselModule,
    ChartModule,
    FlexLayoutModule,
    HighchartsChartModule,
    NgxUiLoaderModule
  ],
  providers: [
    httpInterceptorProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeader,
      multi: true
  },
   NgxSpinnerService,
   NavbarComponent,
   DatePipe,
   AuthGuardService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    ngRedux.configureStore(rootReducer, Initial_State);
  }
}
