import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetricsDashboardService {
  http: HttpClient;
  constructor(http: HttpClient) {
    this.http = http;
  }

  public fetchTotalUsers(fetchDb?: boolean): Observable<any> {
    return this.http.get<any>(environment.adminBaseMetricsUrl + "/total-count?fetchDb=" + fetchDb);

  }

  public fetchActiveUsers(startDate, endDate): Observable<any> {
    let api = environment.adminBaseMetricsUrl + "/active-users";
    let body = {
      "fromDate": startDate,
      "toDate": endDate
    }
    return this.http.post<any>(api, body);
  }

  public fetchUsersByCountry(): Observable<any> {
    return this.http.get<any[]>(environment.adminBaseMetricsUrl + "/users-by-country");
  }

  public fetchUsersbySignUp(): Observable<any> {
    return this.http.get<any>(environment.adminBaseMetricsUrl + "/users-primary-signup");
  }

  public searchUsers(searchId: string): Observable<any[]> {
    return this.http.get<any[]>(environment.searchBaseUrl + "/person?name=" + searchId);
  }

  // Example URI : http://localhost:8080/users/referred-by?userid=30veYDL4x3SIkPzJtWf5wjRiytQ2&&page=0&size=3
  public fetchUsersReferredBy(searchId: string, pageSize: number, pageNumber: number): Observable<any[]> {
    return this.http.get<any[]>(environment.adminBaseMetricsUrl + "/referred-by?userid=" + searchId + "&&page=" + pageNumber + "&&pageSize=" + pageSize);
  }

}
