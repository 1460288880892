import { animate, trigger, state, style, transition } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { BackstageDashboardService } from 'src/app/Services/backstage-dashboard.service';
import { ServiceSnackbarService } from 'src/app/Services/service-snackbar.service';

@Component({
  selector: 'app-bakstage-popular-voicerooms',
  templateUrl: './bakstage-popular-voicerooms.component.html',
  styleUrls: ['./bakstage-popular-voicerooms.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BakstagePopularVoiceroomsComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  popularRoomsinRange: MatTableDataSource<any>;
  endDate: Date;
  startDate: Date;
  minDate: "2017-01-01";
  maxDate: Date;
  allPopularVoiceRooms: MatTableDataSource<any>;
  columnDef: string[];
  selectedVoiceroom: any;
  selectedRangeVoiceroom: any;
  providerchartOptions = {};
  isLoading: boolean = false;
  subscriptionHandles: Subscription[] = [];

  constructor(
    private service: BackstageDashboardService,
    public snackBar: ServiceSnackbarService,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    console.log('booo');
    this.maxDate = new Date();
    this.fetchAllPopularActiveVoiceRoomList();
    this.columnDef = ['expander', 'voiceroomTitle', 'numListeners', 'numSpeakers', 'numModerators', 'createdBy', 'createdOn'];
  }

  ngOnDestroy() {
    this.subscriptionHandles.forEach((handle: Subscription) => {
      handle.unsubscribe();
    });
  }

  fetchPopularVoiceRoomsInRange() {
    const fetchActiveOnly = false;
    const start: String = this.datePipe.transform(this.startDate, 'yyyy-MM-dd');
    const end: String = this.datePipe.transform(this.endDate, 'yyyy-MM-dd');
    this.service.fetchPopularVoiceRoomsInRange(start, end, fetchActiveOnly).subscribe((data) => {
      this.popularRoomsinRange = new MatTableDataSource<any>(data.content);
      this.popularRoomsinRange.paginator = this.paginator.toArray()[0];
    });
  }

  fetchAllPopularActiveVoiceRoomList(){
    const start = null, end = null, fetchActiveOnly = true;
    this.isLoading = true;
    const subscriptionHandle = this.service.fetchPopularVoiceRoomsInRange(start, end, fetchActiveOnly).subscribe((data) => {
      this.isLoading = false;
      this.allPopularVoiceRooms = new MatTableDataSource<any>(data.content);
      this.allPopularVoiceRooms.paginator = this.paginator.toArray()[0];
    });
    this.subscriptionHandles.push(subscriptionHandle);
  }

  applyFilter(value: string) {
    this.allPopularVoiceRooms.filter = value.trim().toLocaleLowerCase();
  }

  collapseVoiceRoom(element){
    if(this.selectedVoiceroom==element){
      this.selectedVoiceroom= null;
    }
    else{
      this.selectedVoiceroom=element;
    }
  }

  applyRangeFilter(value: string) {
    this.popularRoomsinRange.filter = value.trim().toLocaleLowerCase();
  }

  collapseRangeVoiceRoom(element){
    if(this.selectedRangeVoiceroom==element){
      this.selectedRangeVoiceroom= null;
    }
    else{
      this.selectedRangeVoiceroom=element;
    }
  }

  clearDates(){
    this.startDate = null
    this.endDate = null
    this.popularRoomsinRange = null;
  }
}
